import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createStackNavigator} from "@react-navigation/stack";

// Actions & Hooks
import {fetchAccountData} from '../redux/actions/accountActions';
import {useRoute} from '../hooks';

// Screens / Stacks
import {
	AboutScreen, 
	LoginScreen, 
	RegisterScreen, 
	RegisteredScreen, 
	HelpScreen,
	BasicScreen
} from "../screens/initial";

// Components
import {NavigationContainer} from '@react-navigation/native';
import {useTheme} from '../components/Common';

const Stack = createStackNavigator();

const Initial = (props) => {
	// Read from props
	const theme = useTheme();
	const route = useRoute();

	return (
		<NavigationContainer theme={theme}>
			<Stack.Navigator initialRouteName={route.screen ?? 'aboutScreen'} screenOptions={{headerShown: false}}>
				{/* About screen */}
				<Stack.Screen name='aboutScreen' initialParams={route.params}>
					{(screenProps) => (
						<BasicScreen {...screenProps} >
							{(parentProps) => (
								<AboutScreen {...screenProps} {...parentProps} {...props} />
							)}
						</BasicScreen>
					)}
				</Stack.Screen>

				{/* Login screen */}
				<Stack.Screen name='loginScreen' initialParams={route.params}>
					{(screenProps) => (
						<BasicScreen {...screenProps} viewType={'scroll'}>
							{(parentProps) => (
								<LoginScreen {...screenProps} {...parentProps} {...props} />
							)}
						</BasicScreen>
					)}
				</Stack.Screen>

				{/* Register screen for on-boarding */}
				<Stack.Screen name='registerScreen' initialParams={route.params}>
					{(screenProps) => (
						<BasicScreen {...screenProps} viewType={'scroll'}>
							{(parentProps) => (
								<RegisterScreen {...screenProps} {...parentProps} {...props} />
							)}
						</BasicScreen>
					)}
				</Stack.Screen>

				{/* Registered screen with the application */}
				<Stack.Screen name='registeredScreen' initialParams={route.params}>
					{(screenProps) => (
						<BasicScreen {...screenProps} refreshControl={true} viewType={'scroll'} >
							{(parentProps) => (
								<RegisteredScreen {...screenProps} {...parentProps} {...props} />
							)}
						</BasicScreen>
					)}
				</Stack.Screen>
				
				{/* Help screen */}
				<Stack.Screen name='helpScreen' initialParams={route.params}>
					{(screenProps) => (
						<BasicScreen {...screenProps} viewType={'scroll'} refreshControl={true}>
							{(parentProps) => (
								<HelpScreen {...screenProps} {...parentProps} {...props} />
							)}
						</BasicScreen>
					)}
				</Stack.Screen>
			</Stack.Navigator>
		</NavigationContainer>
	);
};

Initial.propTypes = {	
	// Actions
	fetchAccountData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
	fetchAccountData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Initial);