import React from 'react';
import {DarkTheme as PaperDarkTheme} from 'react-native-paper';
import {DarkTheme as NavigationDarkTheme} from '@react-navigation/native';

const combinedDarkTheme = {
    ...NavigationDarkTheme,
    ...PaperDarkTheme,
    colors: {
        ...NavigationDarkTheme.colors,
        ...PaperDarkTheme.colors,
        primary: '#242424',
        success: '#ccffcc',
        accent: '#f9bc0d',
        errors: {
            default: '#f86962',
            light: '#f8d7da',
            dark: '#721c24'
        },
        warnings: {
            default: '#f6d46f',
            light: '#fff4d2',
            dark: '#e2ad08'
        },
		successes: {
            default: '#ccffcc',
            light: '#d4edda',
            dark: '#155724'			
		},
		infos: {
            default: '#cce5ff',
            light: '#cce5ff',
            dark: '#004085'			
		},
        button: {
            primary: '#2d9cdb',
            secondary: '#f2994a',
            success: '#4cb964',
            textLight: '#ffffff',
            textDark: '#282828'
        },
        switch: '#4cd964'
    },
    statusBarStyle: 'light-content'
};

export default combinedDarkTheme;