import Constants from 'expo-constants';

import {
    APPLICATION_UPDATED,
    LOGOUT_ACCOUNT,
    LOGOUT_FAIL,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    REGISTER_APPLICATION,
    ATTEMPT_LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    FETCH_APPLICATION,
} from '../actions/types';

const initialState = {
    id: false,
	profile: false,
    application: false,
    loginAttempt: false,
    loginStatus: false,
    loggedIn: false,
    errors: []
};

const account = (state = initialState, action, debug = Constants?.debugMode ?? false) => {
    const {type, payload} = action;
    var status = false;

    switch (type) {
		default: return state;

        case REGISTER_SUCCESS:
        case REGISTER_FAIL:
        case REGISTER_APPLICATION:
            if (debug) {
                console.log('Driver registering', type, payload);
            }
            return {
                ...state,
                application: {...state.application, ...payload.application},
                errors: payload.errors ?? [],
                loginAttempt: false,
            };

        case ATTEMPT_LOGIN:
            if (debug) {
                console.log('User attempting to log in');
            }
            return {
                ...state,
                loginAttempt: true,
                loginStatus: 'attempt',
                loggedIn: false,
            }

        case LOGIN_SUCCESS:
            if (debug) {
                console.log('User logged into: ' + (payload.type ?? 'missing type'));
            }
            return {
                ...state,
                application: {...state.application, ...payload.application},
                ...payload.account,
                loginStatus: 'success',
                loggedIn: true,
                loginAttempt: false,
            }

        case LOGIN_FAIL:
            if (debug) {
                console.log('User failed to log in', payload.errors);
            }
            return {
                ...state,
                loginStatus: 'error',
                errors: payload.errors,
                loggedIn: false,
                loginAttempt: false,
            }

        case APPLICATION_UPDATED:
            if (debug) {
                console.log('Application updated');
            }
            return {
                ...state,
                ...payload.account,
                application: {...state.application, ...payload.application},
            }

        case FETCH_APPLICATION:
            if (debug) {
                console.log('Retrieved account application from server');
            }
            return {
                ...state,
                ...payload.account,
                application: {...state.application, ...payload.application, loading: false},
            }

        // case LOGOUT_FAIL:
        case LOGOUT_ACCOUNT:
            if (debug) {
                console.log('User logged out');
            }
            return initialState;
    }
};


export default account;