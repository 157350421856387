import Constants from 'expo-constants';

// Import: action types
import {
	APP_REMOTE_UPDATE,
    FETCH_ALL_SCHEMAS,
    APP_FETCH_ATTEMPT,
    APP_FETCH_FAIL,
	SAVE_FOR_TRANSLATION,
	FETCH_TRANSLATIONS,
} from '../actions/types';

const initialState = {
    // App details
    id: 'uk.co.sysx.driverx',             // App bundle ID
    name: 'DriverX',
    version: '',

    // Connectivity
    connectivityState: 0,               // 0 - No Connectivity, 1 - Poor Connectivity, 2 - Good Connectivity
    gps: {
        icon: 'gps_off',				// gps_fixed / gps_not_fixed / gps_off
        signal: false
    },
    network: {
        signal: false,
        icon: 'signal_cellular_off',	// signal_wifi_4_bar / signal_cellular_4_bar / signal_cellular_connected_no_internet_4_bar / signal_cellular_null / signal_cellular_off
        latency: '',
        latencyUnits: ''
    },
    lastResponseDate: '',
    lastResponseTime: '',
    serverHost: '',
    serverPort: '',

    // Permissions
    permissions: {
        camera: false,
        location: false
    },

    // Device
    deviceId: Constants.installationId,
    simNumber: '',
    simState: '',

    // Ready state
    ready: false,
    alert: false,
    error: false,
    errors: [],

	// Translations
	translations: {},

    // Forms schemas
    schemas: {},
    panic: {
        messages: [
            'Press three times',                                                // Shown before the panic button is pressed
            'Are you certain you want to send a panic alert to the hub?',       // Shown when the panic button is pressed once
            'Last chance to cancel, continue?',                                 // Shown when the panic button is pressed the second time
            'Panic alert submitted!'                                            // Shown when the panic button has been pressed the third time
        ],
        status: false,      // submitted | received | processed | error
        message: false,     // The message related to the status (e.g. the error message)
    },
    removed: false,
};

const app = (state = initialState, action, debug = Constants?.debugMode ?? false) => {
    const {type, payload} = action;

    switch (type) {
        default: return {...state, ...payload?.app, removed: false};
			
		case SAVE_FOR_TRANSLATION:
			return {
				...state,
				translations: {
					...state.translations,
					...payload
				}
			}

		case APP_REMOTE_UPDATE:
			return {
				...payload
			};

        case APP_FETCH_ATTEMPT:
            if (debug) {
                console.log('Attempting to fetch application data from server');
            }
            return {
                ...state,
                fetching: true,
            }

        case APP_FETCH_FAIL:
            if (debug) {
                console.log('Failed to fetch app data');
            }
            return {
                ...state,
                fetching: false
            };

        case FETCH_ALL_SCHEMAS:
            if (debug) {
                console.log('Retrieved app form schemas from server', payload);
            }
            return {
                ...state,
                schemas: payload.schemas,
                alert: payload.alert ?? false,
                fetching: false
            };

		case FETCH_TRANSLATIONS:
			if (debug) {
				console.log('Retrieved the translations from the server', payload);
			}
			return {
				...state,
				translations: {...state.translations, ...payload.translations},
                alert: payload.alert ?? false,
				fetching: false
			}
    }
};

export default app;