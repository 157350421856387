import React, { Component } from 'react';
import { StyleSheet, Platform } from 'react-native';

/* Common controls */
import { View, Button, FAB } from './Common';

/* Icons */
import { MaterialCommunityIcon } from './Icons';

/* Camera and permission */
import { Camera } from 'expo-camera';

// Translation
import t from '../translation';

export class ExpoScanner extends Component {

	constructor(props) {
		super(props);

		// Bind functions
		this.onMessage = this.onMessage.bind(this);
		this.switchCamera = this.switchCamera.bind(this);
		this.takePhoto = this.takePhoto.bind(this);
		
		// Set default varialbes
		this.camera = null;
		this.parent = null;
		this.scannedCode = null;

		// State
		this.state = {
			scannedItem: {}
		};
		
		// Parent component
		if (typeof(this.props.parent) !== 'undefined') {
			this.parent = this.props.parent;
		}
	}

	async componentDidMount() {
		// Bind event listener
		if (Platform.OS === 'web') {
			window.addEventListener('message', this.onMessage);
		}
		
		await this.resetScanner();
	}
	
	componentWillUnmount() {
		if (Platform.OS === 'web') {
			window.removeEventListener('message', this.onMessage);
		}
	}

	resetScanner() {
		this.scannedCode = null;
		this.setState({scannedItem: {type: null, data: null}});
	}

	render() {
		// Define Javascript and inject into HTML
		if (this.props.scanner) {
			var html = require('../webviews/zxing/zxing.min.html');

			// Inject Javascript
			var javascript = [];
			for (var index in javascript) {
				html = html.replace('</body>', '<script type=\"text/javascript\">' + javascript[index] + '</script></body>');
			}

			// ZXing Web-based scanner
			return (
					<View style={cameraStyles.cameraWrapper}>
						<View style={[cameraStyles.cameraBorder, this.props.cameraBorderStyle]}>
							<iframe srcDoc={html} style={{width: '100%', height: '100%', border: 0}}></iframe>
						</View>
					</View>
				);
		} else {
			// Use Expo Camera plugin
			var switchCamera = (this.props.allowSwitchCamera ? (<FAB icon="camera-front" mode="contained" onPress={this.switchCamera} small style={this.props.buttonStyle} />) : false);
			return (
					<View style={cameraStyles.cameraWrapper}>
						<View style={[cameraStyles.cameraBorder, this.props.cameraBorderStyle]}>
							<Camera ref={(ref) => {this.camera = ref;}} barCodeScannerEnabled={true} style={[StyleSheet.absoluteFill, cameraStyles.cameraContainer]} />
							<View style={{alignSelf: 'flex-end', margin: 10}}>{switchCamera}</View>
						</View>
						{ !this.props.takePhoto ? null :
						<View style={{alignSelf: 'flex-end', margin: 0, marginTop: 5, marginBottom: 10}}>
							<Button mode="contained" onPress={this.takePhoto} title="Take Photo">
								<MaterialCommunityIcon icon="camera" size={16} />{t('Take Photo')}
							</Button>
						</View>
						}
					</View>
				);
		}
	}
	
	switchCamera() {
		if (this.state.cameraType === Camera.Constants.Type.front) {
			this.setState({cameraType: Camera.Constants.Type.back});
		} else {
			this.setState({cameraType: Camera.Constants.Type.front});
		}
	}
	
	takePhoto() {
		if (this.camera) {
			// Check if handler provided
			if (this.props.takePhoto) {
				this.props.takePhoto(this.camera);
			} else {
				//this.camera.pausePreview();
				var photoOptions = { base64: true };
				this.camera.takePictureAsync(photoOptions).then(photo => {
					console.log(photo);
					//photo.base64 = base64 encoded image
				});
			}
		}
	}
	
	onMessage(event) {
		// Gets barcode for ZXing Web-based scanner
		if (event.data.source === 'ZXing') {
			//console.log(event.data);
			var barcode = event.data.data;
			
			// Barcode is the barcode string
			console.log(barcode);
			
			// Set state
			this.setState({scannedItem: {type: null, data: barcode}});

			// Pass result back to parent
			if (this.parent) {
				this.parent.handleScan(barcode);
			}
		}
	}

}

export const cameraStyles = StyleSheet.create({
	cameraWrapper: {
		flex: 1
	},
	cameraBorder: {
		backgroundColor: '#000000',
		borderWidth: 1,
		borderRadius: 5,
		flex: 1
	},
	cameraContainer: {
		margin: 1
	}
});