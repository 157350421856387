import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import getEnv from '../../config';
const ENV = getEnv();

// Components
import {Button, Text, View, useTheme, Platform} from '../../components/Common';
import {CustomActionButton, CustomSubmitButton} from '../../components/formelements/BasicElements';
import {CustomPicker, CustomSelect} from '../../components/formelements';
import Spinner from '../../components/Spinner';
import FormGenerator from '../../components/PaperFormGenerator';

// Form
import FormSchema from '../../forms/RegisterForm';

// Screens
import RegisteredScreen from './RegisteredScreen';

// Translation
import t from '../../translation';

// Utils
import {preparedFetch} from '../../utils/Http';


/**
 * Registration screen
 */
const RegisterScreen = (props) => {
	const theme = useTheme();
	const {account, app, navigation, registerHandler, route, styles} = props;
	const {baseStyles, buttonStyles} = styles;
	const updateApplication = route?.params?.update ?? false;
	const [formSchema, setFormSchema] = useState(app.schemas?.register ?? FormSchema); 
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Fetch updated register form schema
		loadUpdatedSchema();
	}, []);
	
	/**
	 * Fetch the register schema with the values pre-filled based on the application with the given id.
	 * Set the newly updated form schema, and the loading state to false.
	 */
	function loadUpdatedSchema() {
		if (account.application?.id) {
			preparedFetch(`${ENV.defaultRestServer}/schema/getupdated`, {
				method: 'POST',
				body: {
					platform: 'mobile',	// Use the same screens as the mobile version
					applicationId: account.application?.id,
					type: 'register'
				}
			})
				.then(res => res.json())
				.then(({data}) => {
					setFormSchema(data);
					setLoading(false);
				})
				.catch(err => {
					setLoading(false);
					console.error(`Failed to load updated schema: ${err.message ?? ''}`, err);
				})
		}
		else {
			setLoading(false);
		}
	}

	// Check application status
	if (!updateApplication && ['pending', 'approved', 'rejected', 'submitted'].includes(account.application?.status)) {
		return (<RegisteredScreen {...props} />);
	}

	return loading ? (
				<Spinner title={'Loading form data'} subTitle={'Please Wait...'} style={{flex: 1}} />
			) : (
				<View style={{alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}}>
					<FormGenerator
						schema={formSchema}
						library={{
							picker: CustomPicker,
							action: CustomActionButton,
							submit: CustomSubmitButton,
							select: CustomSelect
						}}
						submitHandler={(values) => registerHandler(values, (account.application?.id ?? false))}
						theme={theme}      			// Currently the theme is only used to display errors when the components are missing from the library
						navigation={navigation} 	// The navigation.navigate() function is used when a screen is not found in the schema.
					>
						<View style={[baseStyles.paddingTop]}>
							<Text style={{alignSelf: 'center', paddingBottom: 5}}>{t('If you already have an account, you may login')}:</Text>
							<Button mode="contained" onPress={() => navigation.navigate('loginScreen')}>{t('Login')}</Button>
						</View>
					</FormGenerator>
				</View>
			);
};

RegisterScreen.propTypes = {
	app: PropTypes.object.isRequired,
	account: PropTypes.object.isRequired,
	configuration: PropTypes.object.isRequired,
	navigation: PropTypes.object.isRequired,
}

export default RegisterScreen;