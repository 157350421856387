// Dependencies
import {createStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import PouchDBStorage from '../pouchdb/PouchDBStorage';

// Redux
import rootReducer from './reducers';
import thunkMiddleware from "redux-thunk";

export const localStorage = (database) => {
	return new PouchDBStorage(database);
};

// Middleware: Redux Persist Config
const persistConfig = (localStorage) => {
	return {
			// Root
			key: 'root',
			// Storage Method (React Native)
			storage: localStorage,
			// Whitelist (Save Specific Reducers)
			whitelist: [
				'app',
				'account',
				'configuration',
			],
			blacklist: []
		};
};

// Used to initialize a non persistent store (used while the database is getting ready)
const localStore = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
    )
);

// Store
const store = (localStorage) => {
	return createStore(
			persistReducer(persistConfig(localStorage), rootReducer),
			applyMiddleware(
				thunkMiddleware,
			)
		);
};

// Middleware: Persisted store
let persistedStore = (store) => {
	return persistStore(store);
};

// Exports
export {store, localStore, persistedStore};