import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Constants from 'expo-constants';
import {parseSchema} from '../translation';

import {FormGenerator} from 'react-native-form-generator';
import {CustomTextInput, CustomActionButton, CustomSubmitButton} from "./formelements/BasicElements";

// Common components
import {View, useTheme} from './Common';
import {CustomPicker, CustomSelect} from './formelements';
import Spinner from './Spinner';

// Form styles
import {FormStyles} from '../constants/Styles';

// Fast object comparison
const isEqual = require("react-fast-compare");

const PaperFormGenerator = ({schema, language, translations, children, ...rest}) => {
	const [formSchema, setFormSchema] = useState(null);
	
	const theme = useTheme();
	// Form styling, based on theme
	const formStyles = FormStyles(theme);
	
	useEffect(() => {
		if (typeof (schema) == 'object') {
			var parsedSchema = parseSchema(schema, (language?.value ?? 'en'), translations);
			if (!isEqual(parsedSchema, formSchema)) {
				setFormSchema(parsedSchema);
			}
		}
	}, [schema, language])

	if (formSchema) {console.log('Render PaperFormGenerator');}
	return formSchema && (
		<View testID={rest.testID ?? null} style={[formStyles.form, rest.style]}>
			<FormGenerator
				library={{
					picker: CustomPicker,
					string: CustomTextInput,
					action: CustomActionButton,
					submit: CustomSubmitButton,
					loader: Spinner
				}}
				schema={formSchema}
				theme={theme}
				debugMode={!!Constants.debugMode}
				{...rest}
			>
				{children ?? null}
			</FormGenerator>
		</View>
	);
};

PaperFormGenerator.propTypes = {
	testID: PropTypes.string,
	schema: PropTypes.object.isRequired,
	language: PropTypes.object,
	translations: PropTypes.object,
}

const mapStateToProps = state => ({
	language: state.configuration.settings.language,
	translations: state.app.translations
})

export default connect(mapStateToProps, null)(PaperFormGenerator);