import {Platform} from 'react-native';
import PouchDB from 'pouchdb';

// Remove prefix
PouchDB.prefix = '';

/* Base64 helpers */
import {btoa, atob} from '../components/Base64';

// Redux
import {store, persistedStore, localStorage} from '../redux/store';

/* SQLite adapter for native */
import * as SQLite from 'expo-sqlite'
import PouchSQLite from './PouchSQLite';

/* PouchDB plugins */
import PouchDBAuthentication from 'pouchdb-authentication';
import PouchDBFind from 'pouchdb-find';
import PouchDBUpsert from 'pouchdb-upsert';

PouchDB.plugin(PouchDBAuthentication);
PouchDB.plugin(PouchDBFind);
PouchDB.plugin(PouchDBUpsert);

// Use SQL when 'native'
if (Platform.OS !== 'web') {
	PouchDB.plugin(PouchSQLite(SQLite));
}

// Add Base64 helpers
if (!global.btoa) {
	global.btoa = btoa;
}

if (!global.atob) {
	global.atob = atob;
}

export default PouchDB

// Default database
export var defaultDB = new PouchDB('DriverX', {auto_compaction: true});

// Function to remove deleted documents by replicating database (without deletions)
export function cleanDB() {
	/*
	return new Promise((resolve, reject) => {
			// Re-establish Redux store / database link
			const _localStorage = localStorage(defaultDB);
			const _store = store(_localStorage);
			const _persistedStore = persistedStore(_store);
			resolve({ready: true, database: {db: defaultDB, store: _store, persistedStore: _persistedStore, localStorage: _localStorage}});		
		});
	*/
	return new Promise((resolve, reject) => {
				// Define variables
				var cleanedDB = new PouchDB('CleanX');
				var originalTableSize; 

				defaultDB.info().then((result)=>{
					originalTableSize = result.doc_count;
					// Replicate without deleted documents
					defaultDB.replicate.to(cleanedDB, {filter : function(doc) { if( doc._deleted ) { return false; } else { return doc; } }}).on('complete', function (result) {
						cleanedDB.info().then((cleanedResult)=>{
							// Ensure all documents copied
							//if (cleanedResult.doc_count == originalTableSize) {
								defaultDB.destroy().then(()=>{
									console.log('Re-creating default database...');
									var activeDB = new PouchDB('DriverX', {auto_compaction: true});
									// Replicate clean database back to original
									cleanedDB.replicate
										.to(activeDB, {filter : function(doc) { if( doc._deleted ) { return false; } else { return doc; } }})
										.on('error', (err) => {console.warn(`Data replication error: ${err.message ?? ''}`);})
										.on('denied', (err) => {console.warn(`Data replication denied: ${err.message ?? ''}`);})
										.on('complete', function (result) {
										console.log('...completed; default database ready for use.');
										cleanedDB.destroy().then(()=>{
											//console.log('Removed temporary database');
											activeDB.info().then((res) => {
												console.log(res);
												defaultDB = activeDB;
												// Re-establish Redux store / database link
												const _localStorage = localStorage(defaultDB);
												const _store = store(_localStorage);
												const _persistedStore = persistedStore(_store);
												resolve({ready: true, database: {db: defaultDB, store: _store, persistedStore: _persistedStore, localStorage: _localStorage}});
											})
										});
									}).on('error', (err) => {console.log('Failed to replicate cleanedDB: ' + (err.message ?? ''))});
								}).catch(err => {console.log('Failed to destroy defaultDB: ' + (err.message ?? ''))});
							//}
						});
					});
				});
			});
}

// Helper function for bulkDocs
export function bulkResultReferences(result) {
	var references = {};
	for (var i in result) {
		var document = result[i];
		if (document.ok == true) {
			references[document.id] = document.rev;
		}
	}
	return references;
}


export async function removeAll(selector) {
	try {
		var result = await defaultDB.find({selector});
		var documents = [];
		for (var i in result.docs) {
			var document = result.docs[i];
			documents.push({ ...document, _deleted: true });
		}
		
		// Delete the documents
		result = await defaultDB.bulkDocs(documents);
		return result;
	} catch (e) {
		console.error(`Failed to remove all pouch db documents: ${e.message ?? ''}`);
		return false;
	}
}