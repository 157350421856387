import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Rules from '@flipbyte/yup-schema';
import t from '../translation';
import {Text, View, useTheme} from '../components/Common';

/**
 * Function ensures that any objects with the keys "function" or "regex" are converted to either Function or RegExp.
 * Modifies the objects in the given array.
 * 
 * @param {Array} dataArray 
 */
 const ensureFunctionAndRegex = (dataArray) => {
    if (Array.isArray(dataArray)) {
        dataArray.forEach((elem, index) => {
            // Find the element which has the regex object
            if (Array.isArray(elem)) {
                elem.forEach((nestedElem, nestedIndex) => {
                    // Check if string value starts with / and ends with /, if so convert from string to regex
                    if (typeof nestedElem === 'object') {
                        let keys = Object.keys(nestedElem);

                        if (keys.length > 0) {
                            // Convert to function from object
                            if (keys.includes('function')) {
                                let args = dataArray[index][nestedIndex].function.args;
                                let body = dataArray[index][nestedIndex].function.body;

                                dataArray[index][nestedIndex] = new Function(args, body);
                            }
                            else if (keys.includes('regex')) {
                                let value = dataArray[index][nestedIndex].regex.value;
                                let flag = dataArray[index][nestedIndex].regex.flag;
								
								if (typeof (value) === 'string') {
									dataArray[index][nestedIndex] = new RegExp(value, flag);
								}
                            }
                        }
                    }
                })
            }
        })
    }
}

/**
 * The validation component parses the provided "validation" array to a yup schema,
 * and it runs the validation against the provided data (name => value).
 * If the data does not pass validation an error message is shown.
 */
const Validation = props => {
	const {language, style, translations, validation, value, name, errorCallback, children} = props;
	const [validationSchema, setValidationSchema] = useState(validation);
	const [error, setError] = useState(false);
	const theme = useTheme();
	
	// Convert validation array to yup rules
	useEffect(() => {
		if (Array.isArray(validationSchema)) {
			setValidationSchema(prepareValidationSchema())
		}
	}, [validation]);
	
	// Validate when the validation schema is set
	useEffect(() => {
		if (typeof (validationSchema) === 'object') {
			validate();
		}
	}, [validationSchema])
	
	// And validate when the value changes
	useEffect(() => {
		if (typeof (validationSchema) === 'object') {
			setError(false);
			validate();
		}
	}, [value]);
	
	// Run the error callback every time the error value changes
	useEffect(() => {
		if (errorCallback && typeof (errorCallback) === 'function') {
			errorCallback(name, error);
		}
	}, [error]);
	
	function prepareValidationSchema() {
		ensureFunctionAndRegex(validationSchema);
		return new Rules([['object'], ['shape', {[name]: validationSchema}]]).toYup();
	}
	
	function validate() {
		if (typeof (validationSchema.validate) === 'function') {
			validationSchema.validate({[name]: value}).catch((err) => {
				if (typeof (err.message) === 'string') {
					setError(t(err.message, language, translations));
				}
			});
		}
	}
	
	return (
		<View style={style ?? {}}>
			{typeof (children) === 'function' ? children(error) : children}
			{typeof (error) === 'string' && (
				<Text style={{color: theme.colors.errors.default}}>{error}</Text>
			)}
		</View>
	)
}

Validation.propTypes = {
	validation: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
	language: PropTypes.string.isRequired,
	style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	translations: PropTypes.object.isRequired,
	errorCallback: PropTypes.func,
}

export default Validation
