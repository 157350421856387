import React from 'react'
import PropTypes from 'prop-types'
import t from '../translation';

// Import: Components
import {TextInput} from '../components/Common';

const CustomTextInput = ({theme, styles, tooltip, name, visibleTooltip, onTooltipClose, label, ...rest}) => {
	return (
		<TextInput {...rest} selectionColor={theme.colors.accent} label={t(label)} />
	)
}

CustomTextInput.propTypes = {
	...TextInput.propTypes,
	tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	visibleTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	onTooltipClose: PropTypes.func,
}

export default CustomTextInput