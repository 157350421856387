import React, {useEffect, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';

// Redux
import {connect} from 'react-redux';
import {logout} from './redux/actions/accountActions';
import {fetchSettings} from './redux/actions/configurationActions';

// Stacks
import {Initial} from './stacks';

// Components
import {useTheme} from './components/Common';
import {StatusBar} from 'expo-status-bar';

const MainRouter = (props) => {
	const {
		app,
		settings, 
		logout,
		fetchSettings,
		onDarkModeToggle
	} = props;
	const theme = useTheme();
	
	useEffect(() => {		
		// Load the settings
		fetchSettings(settings ?? false);
	}, []);

	useEffect(() => {
		if (app.removed) {
			// What to do if the app removal has been triggered
			logout();
		}
	}, [app.removed])
	
	// Effect runs when the darkMode setting changes
	useLayoutEffect(() => {
		onDarkModeToggle(settings?.darkMode?.value);
		// setTheme(configuration.settings?.darkMode?.value ? defaultThemeDark : defaultTheme);
	}, [settings?.darkMode]);

	return (
		<>
			<Initial />
			
			<StatusBar
				style={theme.dark ? 'light' : 'dark'}
				backgroundColor={theme.colors.primary}
			/>
		</>
	);
};

MainRouter.propTypes = {
	account: PropTypes.object.isRequired,
	app: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
}

const mapStateToProps = ({account, app, configuration}) => ({
	account: account,
	app: app,
	settings: configuration.settings
})

const mapDispatchToProps = {
	logout,
	fetchSettings
}

export default connect(mapStateToProps, mapDispatchToProps)(MainRouter);