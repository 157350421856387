import {
	HELP_FETCH_ATTEMPT,
	HELP_FETCH_SUCCESS,
	HELP_FETCH_FAIL
} from '../actions/types';

/**
 * Example initialState.data:
 * {
 * 	register: {
 * 		title: 'Section title'
 * 		items: {
 * 			access: {
* 				title: '',
* 				description: '',
* 				images: [
* 					{
* 						caption: {position: 'top' or 'bottom', value: 'text'},
* 						image: 'link to image'
* 					}
* 				]
* 			},
 * 		}
 * 		...
 * 		video: 'link to video' or false
 * 	}
 */

const initialState = {
	data: {},
	loading: false,
	error: false
};


const help = (state = initialState, action) => {
	const {type, payload} = action;
	
	switch (type) {
		case HELP_FETCH_ATTEMPT:
			return {
				...state,
				loading: true,
				error: false
			}
			
		case HELP_FETCH_SUCCESS:
			return {
				...state,
				data: payload,
				loading: false,
				error: false,
			}
			
		case HELP_FETCH_FAIL:
			return {
				...state,
				loading: false,
				error: payload.error
			}
	
		default:
			return state;
	}
}

export default help;