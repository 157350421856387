import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {View, StyleSheet, Image, Platform} from 'react-native';
import {Text, Divider, Button, IconButton, Surface, useTheme} from 'react-native-paper';
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { ExpoScanner, cameraStyles } from '../ExpoScanner';
import File from '../File';
import * as FileSystem from 'expo-file-system';

// Import: Utils
import t from '../../translation/Base';
import {Camera} from 'expo-camera';

const CustomPicker = ({containerProps, changeHandler, name, value, label, errors, hidden, buttons, fieldHelpers, ...props}) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const maxDocumentFileSize = props.maxDocumentFileSize ?? (1024 * 1024) * 4 // 1MB default
	const allowedImageTypes = props.allowedImageTypes ?? ['jpg', 'png', 'jpeg'];
	const allowedDocumentType = props.allowedDocumentType ?? 'application/pdf';
	const [cameraAvailable, setCameraAvailable] = useState(null); 
	const [ready, setReady] = useState(false);
	const [actionType, setActionType] = useState(props?.actionType ?? 'show_picker');	// Either false, show_picker, or show_camera
	const [takingPhoto, setTakingPhoto] = useState(false);
	const [documentPicker, setDocumentPicker] = useState(typeof (props.documentPicker) !== 'undefined' ? props.documentPicker : true);
	const [imagePicker, setImagePicker] = useState(typeof (props.imagePicker) !== 'undefined' ? props.imagePicker : true);
	const [fileErrors, setFileErrors] = useState([]);
	
	useEffect(() => {
		Camera.getAvailableCameraTypesAsync()
			.then(res => {setCameraAvailable(Array.isArray(res) ? res.length !== 0 : res)})
			.catch(err => {
				setCameraAvailable(false);
				console.error(`Failed to check if camera is available: ${err.message ?? ''}`);
			});
	}, []);
	
	// When the camera has been checked
	useEffect(() => {
		if (cameraAvailable !== null) {
			setReady(true);
		}
	}, [cameraAvailable]);

	async function pickDocument() {
		try {
			// Get the base 64 data
			let file = await DocumentPicker.getDocumentAsync({type: allowedDocumentType, copyToCacheDirectory: false});
			console.log('Picked document: ', file);
			
			if (file.type === 'success') {
				const errors = [];
				
				// Size error
				if (file?.size && file.size > maxDocumentFileSize) {
					errors.push(t(`Maximum allowed size is ${maxDocumentFileSize / 1024 / 1024}MB`));
				}
				
				setFileErrors(errors);
				
				// Set data if no errors
				if (!errors.length) {
					file = new File(file.uri, file.name);
					file = await file.data();
					fieldHelpers.setValue(file);
				}
			}
		} catch (e) {
			console.error(`Pick document failed: ${e.message ?? ''}`);
		}
	}

	async function pickImage() {
		try {
			let data = await ImagePicker.launchImageLibraryAsync({
				aspect: [4, 3],
				base64: true,
				exif: true
			});
			console.log('Picked image: ', data);
			
			if (data?.base64) {
				const type = data.uri.split(';')[0].split('/').pop();	// "uri:image/png;base64,...."
				const errors = [];
				
				// Type error
				if (!allowedImageTypes.includes(type.toLowerCase())) {
					errors.push(`Type [${type}] is not allowed`);
				}
				
				// Data error (e.g. svg files can sometimes be identified as jpg, but lack the ImageLength and ImageWidth exif data)
				if (data?.exif && !data?.exif?.ImageLength && !data?.exif?.ImageWidth) {
					errors.push(t(`Image is missing required data, check that it is not an .svg image`));
				}
				
				setFileErrors(errors);
				
				// Modify image and set the image data if no errors
				if (!errors.length) {
					data = await ImageManipulator.manipulateAsync(data.localUri || data.uri, [{resize: {width: 640}}], {base64: true});
					fieldHelpers.setValue(data);
				}
			}
		} catch (e) {
			console.error(`Pick image failed: ${e.message ?? ''}`);
		}
	}

	function handlePress(action) {
		setActionType(action);
	}
	
	function handleTakePicture(camera) {
		var photoOptions = { base64: true };
		setTakingPhoto('Taking Photo');
		camera.takePictureAsync(photoOptions).then(photo => {
			setTakingPhoto('Converting Photo');
			ImageManipulator.manipulateAsync(photo.localUri || photo.uri, [{resize: {width: 640}}], {base64: true}).then(res => {
				console.log('Photo taken:', res);
				
				setTakingPhoto(false);
				fieldHelpers.setValue(res);
			});
		});
	};

	return (!ready || hidden) ? null : (
		<>
			<Surface {...containerProps} style={[styles.container]}>
				{/* Document/Image preview container*/}
				<View style={styles.previewArea_wrapper}>
					{/* File/image picker options */}
					{(actionType == 'show_picker') && !value && (
						<View style={styles.options_wrapper}>
							<Button style={{display: (documentPicker ? 'flex' : 'none')}} mode={'text'} color={theme.colors.accent} onPress={() => pickDocument()}>Select document</Button>
							<Button style={{display: (imagePicker ? 'flex' : 'none')}} mode={'text'} color={theme.colors.accent} onPress={() => pickImage()}>Select image</Button>
						</View>
					)}

					{/* Camera only */}
					{(actionType == 'show_camera') && cameraAvailable && !value && (
						<ExpoScanner loading={!!takingPhoto} loadingTitle={takingPhoto ?? undefined} takePhoto={handleTakePicture} scanner={false} cameraWrapperStyle={{minHeight: 200}} allowSwitchCamera={false} />
					)}

					{/* Preview file */}
					{value && value.file && value.uri ? (
						<View style={{flex: 1}}>
							<View style={{flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
								<IconButton
									icon={'file'}
									color={theme.colors.accent}
									size={40}
									style={[styles.roundedButton, {backgroundColor: theme.colors.surface}]}
								/>
								<Text>{value.file.name ? value.file.name : 'Missing file name'}</Text>
							</View>

							{/* Clear file data button */}
							<View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
								<IconButton
									icon={'delete'}
									color={theme.colors.accent}
									size={40}
									style={[styles.roundedButton, {backgroundColor: theme.colors.surface}]}
									onPress={() => fieldHelpers.setValue(null)}
								/>
							</View>
						</View>
					) : null}

					{/* Preview image */}
					{value && !value.file && value.uri ? (
						<View style={{flex: 1, minHeight: 200}}>
							{/* Image preview */}
							{!value.file && (
								<Image
									style={[cameraStyles.cameraBorder, {flex: 1, resizeMode: 'contain'}]}
									source={{
										uri: value.uri,
									}}
								/>
							)}

							{/* Clear image data button */}
							<View style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
								<IconButton
									icon={'delete'}
									color={theme.colors.accent}
									size={40}
									style={[styles.roundedButton, {backgroundColor: theme.colors.surface}]}
									onPress={() => fieldHelpers.setValue(null)}
								/>
							</View>
						</View>
					) : null}
				</View>

				{/* Divider */}
				<Divider />

				{/* Buttons container */}
				{typeof (buttons) != 'undefined' && (
					<View style={styles.buttons_wrapper}>
						{Array.isArray(buttons) ? buttons.map(buttonObj => {
							return (
								<IconButton
									key={buttonObj.name}
									mode={'outlined'}
									icon={buttonObj.icon}
									size={40}
									color={theme.colors.accent}
									onPress={() => handlePress(buttonObj.action)}
								/>
							)
						}) : null}
					</View>
				)}

			</Surface>
			{errors && errors[name] && (
				<Text style={styles.error}>{errors[name]}</Text>
			)}
			
			{/* File errors (e.g. not allowed file type, or incorrect file size) */}
			{fileErrors.map((error, index) => (
				<Text key={index} style={styles.error}>{error}</Text>
			))}
		</>
	);
}

const useStyles = (theme) => {
	return StyleSheet.create({
		options_wrapper: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center'
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			elevation: 1
		},
		previewArea_wrapper: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			minHeight: 400
		},
		buttons_wrapper: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			flexShrink: 1,
		},
		pickerButton: {
			borderStyle: 'solid',
			borderWidth: 2,
			borderColor: theme.colors.primary
		},
		roundedButton: {
			overflow: 'hidden',
			borderRadius: 30,
			borderStyle: 'solid',
			borderColor: theme.colors.primary,
			borderWidth: 2,
			marginBottom: 8,
			opacity: 0.8,
			width: 60,
			height: 60,
		},
		error: {
			padding: 8,
			color: theme.colors.error
		}
	})
}


CustomPicker.propTypes = {
	maxFileSize: PropTypes.number,
	allowedImageTypes: PropTypes.array,
	allowedDocumentType: PropTypes.string,
	allowedAndroidStorages: PropTypes.object, // Regex (e.g. /com.android/)
}

export default CustomPicker;

