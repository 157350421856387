import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {View, StyleSheet} from 'react-native';
import {Button} from "react-native-paper";
import {useNavigation} from '@react-navigation/native';


const CustomActionButton = ({form, setCurrentScreen, navigateTo, label, action, ...props}) => {
	const navigation = useNavigation();
    const styles = useStyles();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    // Disable the button when form is submitting
    useEffect(() => {
        if (action !== 'cancel') {
            setButtonDisabled(form.isSubmitting || form.isValidating);
        }
    }, [form.isSubmitting, form.isValidating]);

    // Disable the button if there are any errors
    useEffect(() => {
        if (typeof form.errors === 'object' && !['cancel', 'back'].includes(action)) {
            setButtonDisabled(Object.keys(form.errors).length > 0);
        }
    }, [form.errors])

    const handlePress = () => {
        let screen = typeof navigateTo === 'function' ? navigateTo() : navigateTo;

        switch (action) {
            default:
                form.validateForm().then(errors => {
                    if (Object.keys(errors).length > 0) {
                        console.log('Form action: ' + action + ' errors: ' + errors);
                    }
                    else {
                        console.log('Form navigating to: ' + screen);
                        if (action != 'submit') {
                            setCurrentScreen(action, screen);
                        }
                    }
                });
                break;

            case 'back':
                setCurrentScreen(action)
                break;

            case 'finish':
            case 'cancel':
                const navigationRoutes = typeof (navigation?.dangerouslyGetState) === 'function' ? navigation.dangerouslyGetState().routeNames : [];
				if (navigation && navigationRoutes.includes(screen)) {
					navigation.navigate(screen);
				}
                break;
        }
    }

    return (
        <View style={styles}>
            <Button
                onPress={handlePress}
                mode={"contained"}
                disabled={buttonDisabled}
                style={{margin: 8, flexGrow: 1}}
                {...props}
            >
				{label}
            </Button>
        </View>
    );
};


const useStyles = () => {
    return StyleSheet.create({
        container: {
            padding: 8
        }
    })
}


CustomActionButton.propTypes = {
    ...Button.props,
    form: PropTypes.object.isRequired,
    setCurrentScreen: PropTypes.func.isRequired,
    navigateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    action: PropTypes.string.isRequired,
};

export default CustomActionButton;