// Cryptography
import * as Crypto from 'expo-crypto';
const secretKey = '64ad9d7f450b1a9854aa0c45c40a75f699d81d2a';

/**
 * Returns hashed password promise
 * 
 * @param {type} password
 * @returns {unresolved}
 */
export function hashPassword(password) {
	return Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, secretKey + password);
}