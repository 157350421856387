import * as FileSystem from 'expo-file-system';

class File {

	constructor(uri, name = false, options = {}) {
		this.base64String = this.parseUri(uri);	// The base 64 data is contained within the uri
		this.name = name;
		this.options = {encoding: FileSystem.EncodingType.Base64, ...options};
		this.base64 = this.base64.bind(this);
	}
	
	
	parseUri(uri) {
		if (uri.includes(';base64,')) {
			uri = uri.split(',');
			return uri.pop();
		}
		
		return false;
	}
	
	
	async data() {
		const base64 = this.base64String ?? await this.base64();
		
		return {
			uri: this.name,
			file: {
				name: this.name
			},
			base64
		}
	}
	

	async base64() {
		return FileSystem.readAsStringAsync(this.uri, this.options);
	}

}

export default File;