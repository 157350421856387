import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import t from '../translation';

// Import: Components
import {Button, TextInput, RadioButton, Portal, Dialog, StyleSheet, ScrollView} from './Common';


const CustomSelect = ({theme, styles, tooltip, name, visibleTooltip, onTooltipClose, label, onChange, value, ...rest}) => {
	styles = useStyles(theme);
	const [dialogVisible, setDialogVisible] = useState(false);
	const [textInput, setTextInput] = useState(null);
	
	const toggleDialogVisible = () => {
		setDialogVisible(!dialogVisible);
		if (!dialogVisible && (typeof (textInput?.blur) === 'function')) {
			textInput.blur();
		}
	}

	return (
		<>
			{/* Show dialog with options */}
			{dialogVisible && (
				<Portal>
					<Dialog visible={dialogVisible} style={styles.dialogContainer} onDismiss={toggleDialogVisible}>
						<Dialog.Title>{t(label)}</Dialog.Title>
						<Dialog.Content style={styles.dialogContent}>
							<ScrollView style={styles.listContainer}>
								<RadioButton.Group name={name} onValueChange={onChange} value={value}>
									{rest.options.map(elem => (
										<RadioButton.Item key={elem.name} label={t(elem.name)} value={elem.value} />
									))}
								</RadioButton.Group>
							</ScrollView>

						</Dialog.Content>
						<Dialog.Actions>
							<Button onPress={toggleDialogVisible} color={theme.colors?.onSurface ?? '#000'}>{t('Done')}</Button>
						</Dialog.Actions>
					</Dialog>
				</Portal>
			)}

			{/* The text input with the selected value */}
			<TextInput
				ref={setTextInput}
				name={name}
				value={value}
				label={t(label)}
				showSoftInputOnFocus={false}	// Does not show keyboard on focus
				onFocus={toggleDialogVisible}
				{...rest}
			/>
		</>
	);
};


const useStyles = (theme) => {
	return StyleSheet.create({
		dialogContainer: {
			borderWidth: 2,
			borderStyle: 'solid',
			borderColor: theme.colors.primary
		},
		dialogContent: {
			backgroundColor: theme.colors.background
		},
		listContainer: {
			padding: 8,
			maxHeight: 300
		},
	})
};


CustomSelect.propTypes = {
	...TextInput.props,
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string.isRequired,
	tooltip: PropTypes.object,
	visibleTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	onTooltipClose: PropTypes.func,
};

export default CustomSelect;