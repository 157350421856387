// Function parses the given schema and returns the translations where required
import TranslatedText from "../components/TranslatedText";
import React from 'react';
import {saveForTranslation}  from "../redux/actions/appActions";
import {store} from '../redux/store';
import matchAll from 'string-match-all';

// Value in the schema which require translation are wrapped with $t_ ... _t$
export function parseSchema(schema, language = 'en', translations = {}) {
	// Convert the schema to string
	let stringified = JSON.stringify(schema);

	// Translate all of the values between $t_ and _t$
	stringified = stringified.replace(/\$t_.*?_t\$/g, function (value, ...args) {
		value = value.replace(/(\$t_)|(_t\$)/g, '');
		return translate(value, language, translations);
	});

	return JSON.parse(stringified);
}


/**
 * Returns the translated value based on the provided translations, and specified language.
 * 
 * @param value 
 * @param language 
 * @param translations 		:Object with translations {ro: {"value_as_key": "value"}, ...}
 * @returns 
 */
export default function translate(value, language, translations = {}) {
	if (typeof (language) === 'string') {
		if (typeof (value) === 'string') {
			// Read non-translated parts of string
			const nonTranslatedRegex = /(.*?)\{\{(.*?)\}\}/g;
			var matches = [...matchAll(value, nonTranslatedRegex)];
			
			var translationString = value;
			var replacements = [];
			if (matches.length) {
				translationString = '';
				for (var i in matches) {
					var match = matches[i];
					translationString += match[1] + '{{' + i + '}}';
					replacements[i] = match[2];
				}
			}
			
			let key = translationString;
			var translatedString = translations[language] && translations[language][key] ? translations[language][key] : translationString;
			
			matches = [...matchAll(translatedString, nonTranslatedRegex)];
			var finalTranslation = translatedString;
			if (matches.length) {
				finalTranslation = '';
				for (var i in matches) {
					var match = matches[i];
					finalTranslation += match[1] + replacements[i];
				}
			}
			
			// Save for translation
			if (__DEV__ && (!translations[language] || !translations[language][key])) {
				// Fix required as the defaultDB to which the method has access to is destroyed
				// saveForTranslation(key);
			}
			
			return finalTranslation;
		}
		return value;
	} else {
		return (<TranslatedText t={translate}>{value}</TranslatedText>);
	}
}