import React, {useState} from 'react'
import PropTypes from 'prop-types'

// Import: Components
import {Dialog, Portal, Paragraph, StyleSheet, View, useTheme} from './Common';


const useStyles = (theme) => (StyleSheet.create({
	description: {
		marginTop: 15	
	},
	actions: {
		justifyContent: 'space-between'
	},
	dialog: {
		borderWidth: 2,
		borderStyle: 'solid',
		borderColor: theme.colors.primary,
	},
	content: {
		backgroundColor: theme.colors.background
	},
	container: {
		padding: 8,
	},
	scrollContainer: {
		maxHeight: 400
	},
	error: {
		padding: 8,
		color: theme.colors.error
	},
	success: {
		padding: 8,
		color: theme.colors.success
	}
}))


const CustomDialog = props => {
	const {actions, content, initialData, title, description, visible, handleDismiss, viewType, actionsStyle, dialogStyle, contentStyle, containerStyle, dismissable} = props;
	const [data, setData] = useState(initialData ?? {})
	const theme = useTheme();
	const styles = useStyles(theme);
	
	return (
		<Portal>
			<Dialog theme={theme} visible={visible} style={[styles.dialog, dialogStyle]} dismissable={dismissable ?? false} onDismiss={handleDismiss}>
				<Dialog.Title>{title}</Dialog.Title>
				<Dialog.Content style={[styles.content, contentStyle]}>
					{description && (<Paragraph style={styles.description}>{description}</Paragraph>)}
					
					{viewType && viewType === 'scroll' ? (
						<ScrollView style={[styles.container, styles.scrollContainer, containerStyle ?? {}]}>
							{content(data, setData)}
						</ScrollView>
					) : (
						<View style={[styles.container, containerStyle ?? {}]}>
							{content(data, setData)}
						</View>
					)}
				</Dialog.Content>
				{actions && (
					<Dialog.Actions style={[styles.actions, actionsStyle ?? {}]}>
						{actions(data)}
					</Dialog.Actions>
				)}
			</Dialog>
		</Portal>
	);
}

CustomDialog.propTypes = {
	viewType: PropTypes.oneOf(['scroll', 'view']),
	initialData: PropTypes.object,
	title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
	description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	visible: PropTypes.bool.isRequired,
	
	dismissable: PropTypes.bool,
	handleDismiss: PropTypes.func,
	
	content: PropTypes.func.isRequired,
	actions: PropTypes.func,
	
	actionsStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	dialogStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	contentStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	containerStyle: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

export default CustomDialog;
