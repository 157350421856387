// Account action types
export const LOAD_ACCOUNT = 			'LOAD_ACCOUNT';
export const ATTEMPT_LOGIN = 			'ATTEMPT_LOGIN';
export const LOGIN_FAIL = 				'LOGIN_FAIL';
export const LOGIN_SUCCESS = 			'LOGIN_SUCCESS';
export const LOGOUT_ACCOUNT = 			'LOGOUT_ACCOUNT';
export const LOGOUT_FAIL = 				'LOGOUT_FAIL';
export const REGISTER_APPLICATION = 	'REGISTER_APPLICATION';
export const REGISTER_SUCCESS = 		'REGISTER_SUCCESS';
export const REGISTER_FAIL = 			'REGISTER_FAIL';
export const APPLICATION_UPDATED = 		'APPLICATION_UPDATED';
export const FETCH_APPLICATION = 		'FETCH_APPLICATION';
export const ACCOUNT_FETCH_ATTEMPT = 	'ACCOUNT_FETCH_ATTEMPT';
export const ACCOUNT_FETCH_FAIL = 		'ACCOUNT_FETCH_FAIL';


// Alert action types
export const SET_ALERT = 		'SET_ALERT';
export const REMOVE_ALERT = 	'REMOVE_ALERT';


// App action types
export const APP_REMOTE_UPDATE = 		'APP_REMOTE_UPDATE';
export const APP_FETCH_ATTEMPT = 		'APP_FETCH_ATTEMPT';
export const APP_FETCH_FAIL = 			'APP_FETCH_FAIL';
export const FETCH_ALL_SCHEMAS = 		'FETCH_ALL_SCHEMAS';
export const FETCH_TRANSLATIONS = 		'FETCH_TRANSLATIONS';
export const SAVE_FOR_TRANSLATION = 	'SAVE_FOR_TRANSLATION';


// Authorisation action types
export const AUTHORISATION_ADD = 		'AUTHORISATION_ADD';
export const AUTHORISATION_REMOVE = 	'AUTHORISATION_REMOVE';
export const AUTHORISATION_CLEAR = 		'AUTHORISATION_CLEAR';


// Configuration action types
export const FETCH_SETTINGS = 				'FETCH_SETTINGS';
export const CONFIGURATION_REMOTE_UPDATE = 	'CONFIGURATION_REMOTE_UPDATE';

// Help action types
export const HELP_FETCH_ATTEMPT = 'HELP_FETCH_ATTEMPT';
export const HELP_FETCH_SUCCESS = 'HELP_FETCH_SUCCESS';
export const HELP_FETCH_FAIL = 'HELP_FETCH_SUCCESS';