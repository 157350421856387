import {
	HELP_FETCH_ATTEMPT,
	HELP_FETCH_SUCCESS,
	HELP_FETCH_FAIL
} from './types';

// Import: Utils
import {preparedFetch} from '../../utils/Http';

// Import: Config
import getEnv from '../../config';
const ENV = getEnv();

/* Help data fetching process: attempt -> success/fail */

/**
 * Function handles the start of the help data fetching process, by getting the data from the server via a GET request.
 * On success, the action continues with fetchSuccess().
 * On fail, the action continues with fetchFail().
 * 
 * @param {String} url 
 * @returns 
 */
export const fetchHelpSections = (url = ENV.defaultRestServer) => async (dispatch) => {
	console.log(`Attempting to get help data: ${url}/apphelp/sections/web/application`);
	
	try {
		// Handle the initial help state
		dispatch({
			type: HELP_FETCH_ATTEMPT
		});
		
		// Get the data from the server
		var res = await preparedFetch(`${url}/apphelp/sections/web/application`, {
			method: 'GET',
		});
		res = await res.json();
		
		if (res.status == 'success') {
			// Handle success
			fetchSuccess(res.data)(dispatch);
		}
		else if (res.reason) {
			// Handle fail
			throw new Error(res.reason);
		}
	} catch (err) {
		fetchFail(err.message ?? '')(dispatch);
	}
}


/**
 * Function handles the help data fetch success
 * 
 * @param {Object} data 
 * @returns 
 */
export const fetchSuccess = (data) => async (dispatch) => {
	console.log(`Fetch help data success`);
	
	dispatch({
		type: HELP_FETCH_SUCCESS,
		payload: data
	})
}


/**
 * Function handles the help data fetch fail
 * 
 * @param {String} error
 * @returns 
 */
export const fetchFail = (error) => async (dispatch) => {
	console.error(`Fetch help data fail: ${error}`);
	
	dispatch({
		type: HELP_FETCH_FAIL,
		payload: {error}
	})
}

/* End help data fetching */