import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// Import: Components
import {ImageBackground, Platform, RefreshControl} from 'react-native';
import {Appbar, Image, Menu, View, SafeAreaView, ScrollView, useTheme} from '../components/Common';

// Actions
import {register, login, logout} from '../redux/actions/accountActions';

// Styles
import {BaseStyles, ButtonStyles, FormStyles} from '../constants/Styles';

// Translation
import t from '../translation';
import {Password} from '../utils';

const BasicScreen = (props) => {
	const {register, login, logout, viewType, refreshControl, ...rest} = props;
	const [isMounted, setIsMounted] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const [developerMode, setDeveloperMode] = useState(false);
	var theme = props.theme;
	if (typeof (theme) == 'undefined') {
		theme = useTheme();
	}

	// Styles, based on theme
	const baseStyles = BaseStyles(theme);
	const buttonStyles = ButtonStyles(theme);
	const formStyles = FormStyles(theme);
	const styles = {baseStyles, buttonStyles, formStyles};

	// Background image based on theme
	if (theme.dark) {
		var backgroundImageSource = require('../assets/images/background-blend-dark.jpg');
	} else {
		var backgroundImageSource = require('../assets/images/background-blend-light.jpg');
	}
	
	useEffect(() => {setIsMounted(true)}, []);

	// Application submission handler
	const registerHandler = (values, applicationId) => {
		register(values, applicationId);
	};

	// Account/Application login handler
	const loginHandler = (values) => {
		// Encrypt password
		const {password, ...otherValues} = values?.loginForm ?? values.loginDetailsScreen;
		Password(password).then((passwordHash) => {
			console.log('Hashed password: ' + passwordHash);
			login({...values, loginForm: {password: passwordHash, ...otherValues}});
		});
	}

	// Account/Application logout handler
	const logoutHandler = () => {
		logout();
	}

	return isMounted && (
		<ImageBackground source={backgroundImageSource} style={baseStyles.basicImageContainer} imageStyle={baseStyles.backgroundImage}>
			<SafeAreaView style={[baseStyles.basicContainer]}>
				<Header setDeveloperMode={setDeveloperMode} {...props} />
				{ viewType == 'scroll' ?
					<ScrollView 
						style={[baseStyles.paddedContainer]} 
						contentContainerStyle={{flexGrow: 1}}
						keyboardShouldPersistTap={'handled'}
						refreshControl={refreshControl ? <RefreshControl refreshing={refreshing} onRefresh={() => setRefreshing(true)} /> : undefined}
						horizontal={refreshControl ? false : undefined}
					>
						{props?.children({styles, theme, registerHandler, loginHandler, logoutHandler, developer: {developerMode, setDeveloperMode}, refreshing, setRefreshing, ...rest}) ?? null}
					</ScrollView>
				:
					<View style={[baseStyles.paddedContainer]}>{props?.children({styles, theme, registerHandler, loginHandler, logoutHandler, developer: {developerMode, setDeveloperMode}, ...rest}) ?? null}</View>
				}
			</SafeAreaView>
		</ImageBackground>
	);
};

const HeaderIcon = Platform.OS === 'ios' ? 'dots-horizontal' : 'dots-vertical';
const Header = (props) => {
	const {colors, dark} = useTheme();
	const [menuVisible, setMenuVisible] = useState(false);
	const {navigation, account, logout} = props;

	// Logo image based on theme
	if (dark) {
		var logoSource = require('../assets/images/logo-dark.png');
	} else {
		var logoSource = require('../assets/images/logo-light.png');
	}

	return (
		<Appbar.Header>
			<Image testID={'header_logo'} source={logoSource} style={{flex: 1, height: '100%'}} resizeMode="contain" />
			<Menu
				testID={'header_menu'}
				visible={menuVisible}
				onDismiss={() => setMenuVisible(false)}
				anchor={
					<Appbar.Action color={colors.text} icon={HeaderIcon} onPress={() => setMenuVisible(true)} />
				}
			>
				{account?.loggedIn ? (
					<LoggedInOptions testID={'header_loggedInOptions'} account={account} navigation={navigation} logout={logout} setMenuVisible={setMenuVisible} />
				) : (
					<LoggedOutOptions testID={'header_loggedOutOptions'} account={account} navigation={navigation} setMenuVisible={setMenuVisible} />
				)}
			</Menu>
		</Appbar.Header>
	);
};

const LoggedInOptions = (props) => {
	const {account, logout, navigation, setMenuVisible} = props;

	{/* Logged in (account) menu options */}
	return account?.id ? (
		<>
			<Menu.Item
				onPress={() => {
					logout();
					setMenuVisible(false);
				}}
				title={t('Logout')}
				icon="logout"
			/>
			<Menu.Item
				onPress={() => {
					navigation.navigate('helpScreen');
					setMenuVisible(false);
				}}
				title={t('Help')}
				icon="help"
			/>
		</>
	) : (
		<>
			<Menu.Item
				onPress={() => {
					logout();
					navigation.navigate('loginScreen');
					setMenuVisible(false);
				}}
				title={t('Logout')}
				icon="logout"
			/>
			<Menu.Item
				onPress={() => {
					navigation.navigate('registeredScreen');
					setMenuVisible(false);
				}}
				title={t('Application')}
				icon="clipboard"
			/>
			<Menu.Item
				onPress={() => {
					navigation.navigate('helpScreen');
					setMenuVisible(false);
				}}
				title={t('Help')}
				icon="help"
			/>
		</>
	);
}

const LoggedOutOptions = (props) => {
	const {navigation, setMenuVisible} = props;

	return (
		<>
			<Menu.Item
				onPress={() => {
					navigation.navigate('loginScreen');
					setMenuVisible(false);
				}}
				title={t('Login')}
				icon={'login'}
			/>
			<Menu.Item
				onPress={() => {
					navigation.navigate('registerScreen');
					setMenuVisible(false);
				}}
				title={t('Register')}
				icon="account-edit"
			/>
			<Menu.Item
				onPress={() => {
					navigation.navigate('helpScreen');
					setMenuVisible(false);
				}}
				title={t('Help')}
				icon="help"
			/>
		</>
	)
}

BasicScreen.propTypes = {
	viewType: PropTypes.string,
	app: PropTypes.object.isRequired,
	account: PropTypes.object.isRequired,
	help: PropTypes.object.isRequired,
	configuration: PropTypes.object.isRequired,
	register: PropTypes.func.isRequired,
	login: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	theme: PropTypes.object,
	refreshControl: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	account: state.account,
	app: state.app,
	help: state.help,
	configuration: state.configuration
});

const mapDispatchToProps = {
	register,
	login,
	logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicScreen);