import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux';


const TranslatedText = ({t, translations, settings, children}) => {
	const text = t(children, (settings?.language?.value ?? 'en'), translations);
	return text;
}


TranslatedText.propTypes = {
	t: PropTypes.func.isRequired,
	settings: PropTypes.object.isRequired,
	saveForTranslation: PropTypes.func,
}


const mapStateToProps = (state) => ({
	settings: state.configuration.settings,
	translations: state.app.translations,
})


const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TranslatedText)

