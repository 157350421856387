// Import: PouchDB
import {defaultDB} from '../pouchdb/PouchDB';

// Import: Config
import getEnv from '../config';
const ENV = getEnv();

export async function preparedFetch(url, options, timeoutSeconds = 30) {
	const token = await defaultDB.get('token').catch(err => false);

	const defaultHeaders = {
		'Content-Type': 'application/json',
	};

	options = {...options, headers: {...options.headers, ...defaultHeaders}};
	
	// Add the Device header
	options.headers.Device = ENV.deviceId;
	
	// Add the Key header
	if (token?.value) {
		options.headers.Key = token.value;
	}
	
	// Add the deviceId and stringify POST body
	if (options.method && options.method.toUpperCase() === 'POST') {
		options.body = {...options.body, deviceId: ENV.deviceId};
		options.body = JSON.stringify(options.body);
	}

	return Promise.race([
		fetch(url, options),
		new Promise((_, reject) => setTimeout(() => reject(new Error('Fetch request timed out')), timeoutSeconds * 1000)),
	]);
}