// Import: Dependencies
// Import: Action types
import {
	CONFIGURATION_REMOTE_UPDATE,
	FETCH_SETTINGS,
} from './types';

// Import: Utils
import {preparedFetch} from '../../utils/Http';

// Import: configuration
import getEnv from '../../config';
const ENV = getEnv();


/**
 * Action used to fetch settings from the server.
 *
 * @param {Object} currentSettings 	:The current settings from which to set the values
 * @param {string} restUrl			:The rest url, defaults to the default rest server from config
 * @returns {function(*): Promise<void>}
 */
export const fetchSettings = (currentSettings = false, restUrl = ENV.defaultRestServer) => async (dispatch) => {
	console.log(`Retrieving settings: ${restUrl}/configuration/settings`);
    try {
		let response = await preparedFetch(restUrl + '/configuration/settings', {
            method: 'GET',
        });

        let responseJson = await response.json();
		// Set current settings values
		if (currentSettings && responseJson.settings) {
			for (const key in responseJson.settings) {
				if (currentSettings[key]) {
					responseJson.settings[key].value = currentSettings[key]?.value ?? false;
				}
			}
		}

        dispatch({
            type: FETCH_SETTINGS,
            payload: responseJson
		});
    }
    catch (e) {
        if (e.message) {
            console.error('Error while fetching settings: ' + e.message);
        }
		console.warn('Could not fetch settings');
    }
};


export const remoteConfigurationUpdate = (payload) => async (dispatch) => {
    try {
        // Apply remote update
        dispatch({
            type: CONFIGURATION_REMOTE_UPDATE,
            payload: payload
        });
    }
    catch (e) {
        if (e.message) {
            console.error('Error while applying remote update: ' + e.message);
        }

        return false;
    }	
};