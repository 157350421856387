import React from 'react';
import PropTypes from 'prop-types';
import t from '../translation';

// Import: Components
import {View, Text, Switch} from '../components/Common';

const CustomSwitch = ({theme, styles, tooltip, label, name, visibleTooltip, onTooltipClose, ...rest}) => {
    return (
		<View style={[{alignSelf: 'stretch', paddingTop: 15, paddingBottom: 15}, styles.baseStyles.spacedRow]}>
				<Text>{t(label)}</Text>
				<Switch color={theme.colors.switch} {...rest} />
			</View>
    );
};

CustomSwitch.propTypes = {
	...Switch.props,
	tooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	visibleTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	onTooltipClose: PropTypes.func,
};

export default CustomSwitch;