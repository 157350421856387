import PouchDB from './PouchDB';
import getEnv from '../config';

// Environment variables
const ENV = getEnv();

// Fast object comparison
const isEqual = require("react-fast-compare");

// Database variables
var _syncHandler = null;
var _syncDocuments = 0;
var _connectionDetails = ENV.couch;
var _defaultRestServer = ENV.defaultRestServer;

// Internal variables
var _loaded = false;

// Redux store, and actions
import * as Action from "../redux/actions";

// Import: Utils
import {preparedFetch} from '../utils/Http';

function databaseName(name) {
	return (name + '-' + ENV.deviceId).toLowerCase();
}

function remoteSync(db, store, localStorage, debug) {
	// SystemX <-> DriverX
	var database = databaseName('driverx');
	var connection = _connectionDetails.server + database;
	
	// Request server to create the remote DB
	preparedFetch(_defaultRestServer + '/couch/createdatabase', {
		method: 'POST',
		body: {
			databaseName: database
		},
	})
	.then(res => res.json())
	.then(({data}) => {
		console.log(data);
		var username = data.username;
		var password = data.password;
		var _localDb = db;
		var _remoteDb = new PouchDB(connection, {auth: {username: username, password: password}});
		
		// Establish database connection for event (sync)
		_remoteDb.login(username, password).then(() => {
			sync(_localDb, _remoteDb, connection, store, localStorage, debug);
		}).catch(err => {
			console.error('Remote sync failed: ' + err.message);
		});
	})
	.catch(err => {
		console.error('Remote sync fetch failed: ' + err.message);
	});
	
	// var username = _connectionDetails.username;
	// var password = _connectionDetails.password;
	// var _localDb = db;
	// var _remoteDb = new PouchDB(connection, {auth: {username: username, password: password}});
	
	// // Establish database connection for event (sync)
	// _remoteDb.login(username, password).then(() => {
	// 	sync(_localDb, _remoteDb, connection, store, localStorage, debug);
	// }).catch(err => {
	// 	console.error('Remote sync failed: ' + err.message);
	// });
}

function sync(_localDb, _remoteDb, label, store, localStorage, debug) {
	if (debug) {
		var _console = console;
	} else {
		var _console = {log: function() { return; }};
	}
	
	// Connected to remote database
	console.log('Connected to remote database: ' + label);
	_localDb.info().then(function (info) {
		//_console.log(info);
	});

	// TODO - initial sync before setting up LIVE?
	_remoteDb.info().then(function (info) {
		//_console.log(info);

		// Total documents
		_syncDocuments = info.doc_count;

		// Define sync handler for database
		_syncHandler = _localDb.sync(_remoteDb, {
			live: true,
			retry: true
		}).on('active', function (info) {
			//console.log('Replication active');
			if (typeof (info.direction) !== 'undefined') {
				switch (info.direction) {
					case 'pull':
						_console.log('Data to receive');
						break;

					case 'push':
						_console.log('Data to send');
						break;

					default:
					// to make React happy
				}
			}
		}).on('change', function (change) {
			_console.log('Data changed');

			// Loading percentage
			var percentage = parseInt((change.change.docs_read / _syncDocuments) * 100);
			//_console.log(percentage + '% complete');

			// Loop through changes
			for (var i in change.change.docs) {
				var changedDoc = change.change.docs[i];
				//_console.log(changedDoc);

				switch (changedDoc._id) {
					case 'persist:root':
						// Persistent settings changed; check if remote change
						let localStore = store.getState();
						if ((change.direction == 'pull') && !isEqual(changedDoc.doc, localStore)) {
							// Apply remote configuration update
							localStorage.docRevs[changedDoc._id] = changedDoc._rev;		// Update store revision
							// * commented as it was causing multiple re-renders (tested through expo)
							// if (!isEqual(changedDoc.doc.app, localStore.app)) {
							// 	remoteAppUpdate(JSON.parse(changedDoc.doc.app))(store.dispatch);
							// }
							// if (!isEqual(changedDoc.doc.configuration, localStore.configuration)) {
							// 	remoteConfigurationUpdate(JSON.parse(changedDoc.doc.configuration))(store.dispatch);
							// }
						}
						break;

					default:
						if (typeof (changedDoc.docType) == 'string') {
							// Check document type
							switch (changedDoc.docType.toLowerCase()) {
								case 'serverdelete':
									deleteDocument(_localDb, changedDoc.id, changedDoc._id);
									break;

								case 'account':
									accountAdded(changedDoc)(store.dispatch);
									break;

								case 'serverfail':
									_console.log('Server returned fail response.');
									
								case 'serveraction':
									_console.log('Attempting to call [' + changedDoc.type + '.' + changedDoc.id + '] action');
									// Check if action is handled by app, if so call action
									if (typeof Action[changedDoc.type][changedDoc.id] == 'function') {
										Action[changedDoc.type][changedDoc.id](changedDoc)(store.dispatch);
									}
									break;
										
								default:
									// do nothing
									break;
							}
						}
						break;
				}
			}

		}).on('paused', (info) => {
			// replication paused (e.g. replication up to date (when live), user went offline)
			_console.log('Replication complete');
			// Populate local variables
			if (!_loaded) {
			} else {
				_console.log('May need to handle DB updates here... (' + Date.now() + ')');
			}
		}).on('denied', function (err) {
			// a document failed to replicate (e.g. due to permissions)
		}).on('error', function (err) {
			// unhandled error (shouldn't happen); maybe unauthorised etc.
			_console.log(err);
			_console.log('Replication error');
		}).on('complete', function (info) {
			// replication was complete, or cancelled (when live)
			_console.log('Replication cancelled');
		});
	});
}

function deleteDocument(database, documentId, requestDocumentId) {
	if (requestDocumentId) {
		console.log('Received request to delete document: ' + documentId);
	}
	// Read and remove document
	database.get(documentId).then(function (doc) {
		return database.remove(doc);
	}).catch(function (err) {
		console.log(err);
	});

	// Remove initial request
	if (requestDocumentId) {
		deleteDocument(database, requestDocumentId);
	}
}

export {remoteSync};