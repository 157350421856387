import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

// Import: Translation
import t from '../../translation';

// Import: Components
import {Button, Portal, Dialog, List, StyleSheet, useTheme} from '../Common';
import CustomDialog from '../CustomDialog';
import {Linking} from 'react-native';

const PermissionsNeededModal = (props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const {modalVisible, permissions, checkPermissions, openSettings} = props;
	
	return (
		<CustomDialog
				title={t('The following permissions need to be granted:')}
				initialData={{}}
				dialogStyle={{alignSelf: 'center', maxWidth: 720, width: '100%'}}
				visible={modalVisible}
				dismissable={false}
				content={(data, setData) => (
					<List.Section>
						{permissions && Object.keys(permissions).map(key => {
							const permission = permissions[key];
							
							return (
								<List.Item
									key={`requiredService_${key}`}
									style={{marginHorizontal: -15}}
									title={t(permission.title ?? 'Missing title')}
									description={permission.description ? t(permission.description) : undefined}
									left={
										(props) => (
											<List.Icon {...props}
												color={permission.granted ? theme.colors.successes.default : null}
												icon={permission.granted ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
											/>
										)
									}
									right={props => typeof(permission.canAskAgain) !== 'undefined' && (
											<Button 
												color={theme.colors.onSurface} 
												onPress={() => permission.canAskAgain ? checkPermissions(key) : Linking.openSettings()} 
												mode={'text'}
											>
												{t('Set')}
											</Button>
										)
									}
								/>
							);
						})}
					</List.Section>
				)}
				actions={(data) => (
					<Button onPress={checkPermissions} mode={'contained'}>{t('Done')}</Button>
				)}
			/>
	)
	
	return (
			<Portal>
				<Dialog visible={modalVisible} style={styles.dialogContainer} dismissable={false}>
					<Dialog.Title>{t('The following permissions need to be granted:')}</Dialog.Title>
					
					{/* List with services */}
					<Dialog.Content style={styles.dialogContent}>
						<List.Section>
							{permissions && Object.keys(permissions).map(key => {
								const permission = permissions[key];
								
								return (
									<List.Item
										key={`requiredService_${key}`}
										style={{marginHorizontal: -15}}
										title={t(permission.title ?? 'Missing title')}
										description={permission.description ? t(permission.description) : undefined}
										left={
											(props) => (
												<List.Icon {...props}
													color={permission.granted ? theme.colors.successes.default : null}
													icon={permission.granted ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
												/>
											)
										}
										right={props => typeof(permission.canAskAgain) !== 'undefined' && (
												<Button 
													color={theme.colors.onSurface} 
													onPress={() => permission.canAskAgain ? checkPermissions(key) : Linking.openSettings()} 
													mode={'text'}
												>
													{t('Set')}
												</Button>
											)
										}
									/>
								);
							})}
						</List.Section>
					</Dialog.Content>
					
					<Dialog.Actions>
						<Button onPress={checkPermissions} mode={'contained'}>{t('Done')}</Button>
					</Dialog.Actions>
				</Dialog>
			</Portal>
	)
}

const useStyles = (theme) => {
	return StyleSheet.create({
		dialogContainer: {
			borderWidth: 2,
			borderStyle: 'solid',
			borderColor: theme.colors.primary
		},
		dialogContent: {
			backgroundColor: theme.colors.background
		},
		listContainer: {
			padding: 8,
			maxHeight: 300
		},
	})
};

PermissionsNeededModal.propTypes = {
	permissions: PropTypes.object.isRequired,
	modalVisible: PropTypes.bool.isRequired,
	checkPermissions: PropTypes.func.isRequired,
}

export default PermissionsNeededModal;
