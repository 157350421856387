import React from 'react';
import PropTypes from 'prop-types';
import {Button, useTheme} from "react-native-paper";


const CustomButton = (props) => {
    const {text, styles, colour, mode, onPress} = props;

    const theme = useTheme();
	const color = theme.colors.button[colour ? colour : 'primary'];

    return (
        <Button
            {...props}
            style={styles ? styles : ''}
            mode={mode}
            color={color}
            onPressHandler={() => onPress}
        >
            {text}
        </Button>
    );
};

CustomButton.propTypes = {
    ...Button.props,
    mode: PropTypes.string.isRequired,
    styles: PropTypes.array,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    colour: PropTypes.string,
    onPress: PropTypes.func
};

export default CustomButton;