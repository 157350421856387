import React, {Fragment} from 'react';

// Translation
import t from '../translation';

// Common Components
import {ActivityIndicator, Text, View, useTheme} from './Common';

// Colors
import Colors from '../constants/Colors';

const Spinner = (props) => {
    let {title, titleStyle, subTitle, subTitleStyle, color, size, style, children, position, noTranslation, testID} = props;
    const theme = useTheme();

    // Defaults
    title = title ?? 'Please wait...';
    color = color ?? theme.dark ? Colors.offWhite : Colors.darkGrey;
    size = size ?? 48;

    return (
		<View testID={testID ?? null} style={style}>
            {position === 'below' && children ? children : null}
            <View
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <ActivityIndicator animating={true} color={color} size={size} />
				{title ? <Text style={[defaultTitleStyle, titleStyle, {color: color}]}>{noTranslation ? title : t(title)}</Text> : null}
				{subTitle ? <Text style={[defaultSubTitleStyle, subTitleStyle]}>{noTranslation ? subTitle : t(subTitle)}</Text> : null}
            </View>
            {position === 'above' && children ? children : null}
		</View>
    );
};

export default Spinner;

const defaultTitleStyle = {
    paddingTop: 20,
    fontSize: 18,
};

const defaultSubTitleStyle = {
    paddingTop: 10,
    fontSize: 14,
};
