const LoginForm = {
	"initialScreen": "registerForm",
	"screens": {
		"registerForm": {
			"title": "$t_Register_t$",
			"description": "$t_Please enter your registration details below._t$",
			"navigateOnSubmit": "home",
			"actions": {
				"cancel": {
					"type": "action",
					"action": "cancel",
					"label": "Cancel",
					"navigateTo": "aboutScreen",
					"props": {
						"mode": "contained"
					}
				},
				"submit": {
					"type": "submit",
					"action": "submit",
					"label": "$t_Register_t$",
					"props": {
						"mode": "contained"
					}
				}
			},
			"properties": {
				"email": {
					"type": "string",
					"label": "$t_Email_t$",
					"props": {
						"mode": "outlined",
						"dense": true
					},
					"validation": [
						[
							"string"
						],
						[
							"email",
							"$t_Please enter your email address_t$"
						],
						[
							"required",
							"$t_Email is required_t$"
						]
					]
				},
				"password": {
					"type": "string",
					"value": "",
					"label": "$t_Password_t$",
					"props": {
						"mode": "outlined",
						"dense": true,
						"secureTextEntry": true
					},
					"validation": [
						[
							"string"
						],
						[
							"required",
							"$t_Please enter your password_t$"
						],
						[
							"min",
							6
						]
					]
				}
			}
		}
	}
};

export default LoginForm;