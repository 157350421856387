import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

// Import: Translation
import t from '../../translation';

// Import: Components
import {Portal, Dialog, List, StyleSheet, useTheme} from '../Common';
import CustomDialog from '../CustomDialog';

const ServicesNeededModal = (props) => {
	const theme = useTheme();
	const styles = useStyles(theme);
	const {modalVisible, services, dismissable} = props;
	
	return (
		<CustomDialog
				title={t('The following services need to be enabled:')}
				initialData={{}}
				dialogStyle={{alignSelf: 'center', maxWidth: 720, width: '100%'}}
				visible={modalVisible}
				dismissable={false}
				content={(data, setData) => (
					<List.Section>
							{services && Object.keys(services).map(key => {
								const service = services[key];
								
								return (
									<List.Item
										key={`requiredService_${key}`}
										title={t(service.title ?? 'Missing title')}
										left={
											(props) => (
												<List.Icon {...props}
													color={service.enabled ? theme.colors.successes.default : null}
													icon={service.enabled ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
												/>
											)
										}
									/>
								);
						})}
					</List.Section>
				)}
			/>
	)
}

const useStyles = (theme) => {
	return StyleSheet.create({
		dialogContainer: {
			borderWidth: 2,
			borderStyle: 'solid',
			borderColor: theme.colors.primary
		},
		dialogContent: {
			backgroundColor: theme.colors.background
		},
		listContainer: {
			padding: 8,
			maxHeight: 300
		},
	})
};

ServicesNeededModal.propTypes = {
	services: PropTypes.object.isRequired,
	modalVisible: PropTypes.bool.isRequired,
	dismissable: PropTypes.bool,
}

export default ServicesNeededModal
