import React from 'react';

// Common components
import {Button, Image, Text, View} from '../../components/Common';

// Translation
import t from '../../translation';

const AboutScreen = ({account, navigation, styles}) => {
	const {baseStyles, buttonStyles} = styles;

	return (
		<View style={[baseStyles.basicContainer, baseStyles.center, {alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}]}>
			<View style={baseStyles.center}>
				<Image source={require('../../assets/images/96x96.png')} resizeMode="contain" style={{width: 96, height: 96}}></Image>
			</View>
			<View style={[baseStyles.center, baseStyles.paddingTop]}>
				<Text >{t(account?.loggedIn ? 'Logged into application progress' : 'You are not currently logged in')}</Text>
			</View>
			<View style={{paddingTop: 50}}>
				{account?.loggedIn && account.application ? (
					<Button mode="contained" onPress={() => navigation.navigate('registeredScreen')} style={buttonStyles.default}>{t('Application')}</Button>
				) : (
					<>
						<Button mode="contained" onPress={() => navigation.navigate('loginScreen')} style={[buttonStyles.marginBottom, buttonStyles.default]}>{t('Login')}</Button>
						<Button mode="contained" onPress={() => navigation.navigate('registerScreen')} style={buttonStyles.default}>{t('Register')}</Button>
					</>
				)}
			</View>
		</View>
	);
};

export default AboutScreen;