import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-paper';

// WebBrowser for hyperlinks
import * as WebBrowser from 'expo-web-browser';

/**
 * Strips HTML tags from a string (e.g. <b> (Bold) and <i> (Italic))
 * Note: The contents of the tags is left in place; i.e. bold and italic formatting is removed
 * 
 * @param {type} string
 * @returns {StripHtml.stripString}
 */
export function StripHtml(string) {
	var stripString = string;
	
	const messageRegex = /<(?:b|strong|em|i|p)>(.*?)<\/(?:\1)>/g
	const matches = string.matchAll(messageRegex);
	if (matches && matches.length) {
		for (var i = 0; i < matches.length; i++) {
			var match = matches[i];
			stripString = stripString.replace(match, match.replace(messageRegex, '$1'));
		}
	}
	
	return stripString;
}

/**
 * Parses HTML string with tags into React Native Text components (e.g. <b> (Bold) and <i> (Italic))
 * 
 * @param {type} props
 * @returns {Array|ParseHtml.textElements}
 */
export function ParseHtml(props) {
	// Read properties
	var keyPrefix = (props.keyPrefix ? props.keyPrefix : 0);
	var htmlString = (props.children ? props.children : '');
	var wrapText = (props.wrapText ? props.wrapText : false);

	// Remove new lines (e.g. \r\n, \n, \r)
	htmlString = htmlString.replace(/(\r\n|\n|\r)/gm,""); 
	//console.log(htmlString);
	
	// Define elements
	var elements = [];
	
	// HTML tags regular expression
	const htmlRegex = /<(b|strong|em|i|p)>(.*?)<\/\1>|<\/?\s?br\s?\/?>|<a.*?>(.*)<\/a>/
	const matches = htmlString.match(htmlRegex);
	if (matches && matches.length) {
		//console.log(matches);
	
		// Regular expressions for various HTML tags
		const hyperlinkRegex = /^<a href=('|")(\S*?)(?:\1).*?>(.*?)<\/a>/
		const boldRegex = /^<(b|strong)>(.*?)<\/\1>/
		const emphasisRegex = /^<em>(.*?)<\/em>/
		const italicRegex = /^<i>(.*?)<\/i>/
		const paragraphRegex = /^<p>(.*?)<\/p>/
		const newLineRegex = /^<\/?\s?br\s?\/?>/
		
		// Convert match into an array of replacement elements; use 'tag' where possible to avoid regex tests (slow on mobiles)
		var match = matches[0];
		var tag = matches[1] ?. toLowerCase() ?? '';
		//console.log(match);
		var onPress = (props.onPress ? props.onPress : null);

		// Add any text BEFORE the HTML tag; MUST be text or it would have matched an earlier tag
		var firstCharacter = htmlString.indexOf(match);
		if (firstCharacter > 0) {
			// Has text BEFORE the HTML tag
			 var initialString = htmlString.substring(0, firstCharacter);
			//console.log('Text BEFORE tag: ', initialString);
			elements.push(<Text key={"initial:" + keyPrefix} style={props.style} onPress={onPress}>{initialString}</Text>);
		}

		// Parse the text within the match
		var innerHtml = htmlString.substring(firstCharacter, (match.length + firstCharacter));
		if (innerHtml) {
			//console.log('Text WITHIN tag: ', innerHtml);
			var nestedHtml = '';
			var newStyle = [StyleSheet.flatten(props.style)];

			// Check if hyperlink
			if (hyperlinkRegex.test(innerHtml)) {
				// 2 = href, 3 = inner HTML
				var hyperlinkParts = match.match(hyperlinkRegex);
				//console.log('New hyperlink:', hyperlinkParts[2], hyperlinkParts[3]);
				nestedHtml = hyperlinkParts[3];
				onPress = () => { WebBrowser.openBrowserAsync(hyperlinkParts[2]); };
			}

			// Check if bold
			//if (boldRegex.test(match)) {
			if ((tag === 'b' || tag === 'strong')) {
				// 2nd match element; 1st is either 'b' or 'strong'
				nestedHtml = match.replace(boldRegex, '$2');
				newStyle.push({...Styles.bold, ...props.boldStyle});
			}

			// Check if emphasis
			//if (emphasisRegex.test(match)) {
			if (tag === 'em') {
				nestedHtml = match.replace(emphasisRegex, '$1');
				newStyle.push({...Styles.emphasis, ...props.emphasisStyle});
			}

			// Check if italic
			//if (italicRegex.test(match)) {
			if (tag === 'i') {
				nestedHtml = match.replace(italicRegex, '$1');
				newStyle.push({...Styles.italic, ...props.italicStyle});
			}

			// Check if paragraph
			//if (paragraphRegex.test(match)) {
			if (tag === 'p') {
				nestedHtml = match.replace(paragraphRegex, '$1');
				elements.push(<View key={"newparagraph:" + keyPrefix} style={{marginBottom: 10}}></View>);
				//newStyle.push([Styles.paragraph, props.paragraphStyle]);					
			}

			// Check if newline
			if (newLineRegex.test(match)) {
				//console.log('New line');
				elements.push(<Text key={"newline:" + keyPrefix} style={{ ...props.newLineStyle }}>{"\n"}</Text>);
			}				

			if (nestedHtml) {
				//console.log(nestedHtml);
				elements.push(<ParseHtml key={"nested:" + keyPrefix} {...props} style={newStyle} onPress={onPress} wrapText={true}>{nestedHtml}</ParseHtml>);
			}
		}

		// Add any text AFTER the HTML tag
		var lastCharacter = firstCharacter + match.length;
		var remainingString = htmlString.substring(lastCharacter);
		if (remainingString)  {
			// Has text AFTER the HTML tag
			//console.log('Text AFTER tag: ', remainingString);
			elements.push(<ParseHtml key={"remaining:" + keyPrefix} {...props}>{remainingString}</ParseHtml>);
		}
	} else {
		// No HTML to parse
		//console.log('No HTML, text only: ', htmlString);
		elements.push(<Text key={"plainText:" + keyPrefix + ":noHtml"} style={props.style} onPress={props.onPress}>{htmlString}</Text>);
	}

	//console.log(elements);
	if (wrapText) {
		return (<Text>{elements}</Text>);
	}
	return elements;
	
	/*
	var containedCounter = 0;
	var containedElements = [];
	for(var i in elements) {
		var element = elements[i];
		if ((typeof(element) === 'string') && (element === 'paragraph')) {
			containedCounter++;
			continue;
		}
		if (typeof(containedElements[containedCounter]) === 'undefined') {
			containedElements[containedCounter] = [element];
		} else {
			containedElements[containedCounter].push(element);
		}
	}
	console.log(containedElements);
	return containedElements;
	 * 
	 */
}

// Styles
const Styles = {
	hyperlink: {
		
	},
	bold: {
		fontWeight: 'bold'
	},
	emphasis: {
		fontStyle: 'italic'
	},
	italic: {
		fontStyle: 'italic'
	},
	paragraph: {
		
	}
};