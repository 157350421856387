import {parse} from 'react-native-svg';
import PouchDBStorage from 'redux-persist-pouchdb';

// Fast object comparison
const isEqual = require("react-fast-compare");

class customStorage extends PouchDBStorage {
	// async setItem(key, value) {
	// 	try {
	// 		const doc = JSON.parse(value);
	// 		const _rev = this.docRevs[key];
	// 		const result = await this.db.put({
	// 			_id: key,
	// 			_rev,
	// 			doc
	// 		});
	// 		this.docRevs[key] = result.rev;
	// 		return result;
	// 	} catch (err) {
	// 		// Check for document conflict on persistent root
	// 		if ((err.status === 409) && (err.id === 'persist:root')) {
	// 			// Ignore race condition on persistent storage
	// 			/*
	// 			const existingDoc = await this.db.get(key);
	// 			const doc = JSON.parse(value);
	// 			const revisedDoc = {...existingDoc, doc: {doc}};
	// 			await this.db.put(revisedDoc);
	// 			*/
	// 		} else {
	// 			console.error(err);
	// 		}
	// 	}
	// }

	// Set item using upsert to avoid conflicts
	async setItem(key, value) {
		try {
			const result = await this.db.upsert(key, function (existingDoc) {
				let newDoc = JSON.parse(value);
				if (isEqual(existingDoc.doc, newDoc)) {
					return false;
				}
				return {doc: newDoc};
			});
			return result;
		} catch (err) {
			console.error(`Failed to set pouchDB item: ${err.message ?? ''}`);
		}
	}
	
};

export default customStorage;
