// Import: dependencies
// import Geolocation from '@react-native-community/geolocation';

// Import: action types
import {
	APP_REMOTE_UPDATE,
    FETCH_ALL_SCHEMAS,
    APP_FETCH_ATTEMPT,
    APP_FETCH_FAIL,
	FETCH_TRANSLATIONS,
} from './types';
import {defaultDB} from '../../pouchdb';

// Import: Utils
import {preparedFetch} from '../../utils/Http';
import {prepareData} from '../../utils/Pouch';

// Import: config
import getEnv from '../../config';
const ENV = getEnv();


export const fetchAppData = (name, applicationId = false, accountId = false) => async (dispatch) => {
    defaultDB.upsert('fetchrequest:' + name, (doc) => {
        return {...doc, ...prepareData('fetchrequest', {name, applicationId, accountId})};
    }).then(res => {
        dispatch({
            type: APP_FETCH_ATTEMPT
        });
    }).catch(err => {
        console.log('Failed app fetch data attempt: ' + (err.message ?? ''))
    })
}


export const fetchAppDataFail = (document) => async (dispatch) => {
    const {payload} = document;

    defaultDB
        .get(document._id)
        .then(function (doc) {
            return defaultDB.remove(doc);
        })
        .then(function () {
            dispatch({
                type: APP_FETCH_FAIL,
                payload: {
                    errors: payload.errors
                }
            })
        })
        .catch(function (err) {
            console.error('Fetch failed: ' + (err.message ?? ''));
            return false;
        });
}


/**
 * Function retrieves all of the schemas required for the app.
 *
 * @param {string} url      :Defaults to the default rest server set in the config
 * @returns
 */
export const fetchAllSchemas = (url = ENV.defaultRestServer) => async (dispatch) => {
    console.log('Retrieving form schemas: ' + url + '/schema/getall');

    try {
        // Get the schema from the server
        let response = await preparedFetch(url + '/schema/getall', {
            method: 'POST'
        })

        let data = await response.json();

        if (data.status && data.status == 'success') {
            // Parse the schemas and apply translation
			// Object.keys(data.schemas).forEach(key => {
			// 	data.schemas[key] = parseSchema(data.schemas[key]);
			// })
			// console.log(data.schemas);

            dispatch({
                type: FETCH_ALL_SCHEMAS,
                payload: {
                    schemas: data.schemas,
                    alert: data.alert ?? false
                }
            })
        }
        else {
            throw new Error(data.reason);
        }
    }
    catch (e) {
        if (e.message) {
            console.error('Error while fetching application forms schemas: ' + e.message);
        }
    }

}


export const fetchTranslations = (url = ENV.defaultRestServer) => async (dispatch) => {
    console.log('Retrieving translations: ' + url + '/translations');
    
    try {
        let response = await preparedFetch(`${url}/device/translations`, {
            method: 'GET',
        });
        
        response = await response.json();
        
        if (response.status && response.status === 'success') {
            dispatch({
                type: FETCH_TRANSLATIONS,
                payload: {translations: response.translations, alert: response.alert ?? false}
            })
        }
        else {
            throw new Error(response.reason);
        }
    } 
    catch (e) {
        console.error(`Failed to retrieve translations: ${e.message ?? ''}`);
    }
}


export const remoteAppUpdate = (payload) => async (dispatch) => {
	try {
		// Apply remote update
		dispatch({
			type: APP_REMOTE_UPDATE,
			payload: payload
		});
	}
	catch (e) {
		if (e.message) {
			console.error('Error while applying remote update: ' + e.message);
		}

		return false;
	}
};


// *Moved to utils
// function prepareData(docType, values = {}, asArray = false) {
//     const date = new Date();
//     let data = {
//         docType,
//         timestamp: parseInt(date.getTime() / 1000)    // timestamp in seconds
//     };

//     if (!asArray) {
//         data._id = docType + (values.name ? '_' + values.name : '');
//     }

//     switch (docType) {
//         default:
//             data = {...data, ...values};
//             break;

//         case 'login':
//             if (!values?.loginForm?.email || !values?.loginForm?.password) {
// 				return false;
// 			}
//             data = {...data, ...values.loginForm};
//             break;
//     }

//     return asArray ? [data] : data;
// }