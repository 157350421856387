import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

// Import: utils
import t from '../translation'

// Import: Actions
import {
	fetchHelpSections
} from '../redux/actions/helpActions';

// Import: Components
import {Button, List, Title, Paragraph, BackHandler, View, Image, Linking, useStore} from '../components/Common';
import { Spinner } from '../components';

/**
 * Functional component with the content of the selected help item
 */
const HelpSection = ({data, setSelected}) => {
	const defaultImageHeight = 200;
	
	// On Mount
	useEffect(() => {
		BackHandler.addEventListener('hardwareBackPress', backAction);
		
		return () => {
			BackHandler.removeEventListener('hardwareBackPress', backAction);
		}
	}, []);
	
	/**
	 *  The back handler attaches to the "hardwareBackPress" event.
	 *	Returning false will allow the default navigation to work.
	 * @returns {Boolean}
	 */
	 function backAction() {
		setSelected(null);
		return true;
	}
	
	return (
		<View style={{flex: 1}}>
			<List.Section>
				<Title>{data.title ? t(data.title) : 'Missing title'}</Title>
				
				{/* Description */}
				{data.description && (
					<Paragraph>{t(data.description)}</Paragraph>
				)}
				
				{/* Image with caption */}
				{data.images && Array.isArray(data.images) ? data.images.map((imageItem, index) => (
					<View key={`image_${index}`} style={{marginVertical: 15}}>
						{/* Caption above */}
						{imageItem.caption?.position == 'top' && (
							<Paragraph>{t(imageItem.caption.value)}</Paragraph>
						)}
						
						{/* Image */}
						<Image
							style={{flex: 1, minHeight: imageItem.imageHeight ?? defaultImageHeight, resizeMode: 'contain'}}
							height={imageItem.imageHeight ?? defaultImageHeight}
							source={{
								uri: imageItem.image,
							}}
						/>
						
						
						{/* Caption below */}
						{imageItem.caption?.position == 'bottom' && (
							<Paragraph>{t(imageItem.caption.value)}</Paragraph>
						)}
					</View>
				)) : null}
				
				
			</List.Section>
			<Button mode={'contained'} onPress={backAction}>{t('Back')}</Button>
		</View>
	)
}
HelpSection.propTypes = {
	data: PropTypes.object.isRequired,
	setSelected: PropTypes.func.isRequired,
}


/**
 * Functional component with group of help items
 */
const HelpItemsGroup = ({data, setSelected}) => {
	function handleVideoPress() {
		Linking.canOpenURL(data.video).then(res => {
			if (res) {
				Linking.openURL(data.video);
			}
		})
	}
	
	return data?.items ? (
		<List.Section>
			<Title>{data.title ? t(data.title) : 'Missing title'}</Title>
			
			{/* Video tutorial link */}
			{data.video && (
				<List.Item 
					description={t('Watch video tutorial')}
					onPress={handleVideoPress}
				/>
			)}
			
			{/* Help items */}
			{Object.keys(data.items).map(item => (
				<List.Item 
					key={item} 
					description={data.items[item].title ? t(data.items[item].title) : 'Missing Item Title'}
					onPress={() => setSelected(item)}
				/>
			))}
		</List.Section>
	) : null;
}
HelpItemsGroup.propTypes = {
	data: PropTypes.object.isRequired,
	setSelected: PropTypes.func.isRequired,
}


/**
 * Main component for the Help Screen
 */
const HelpScreen = props => {
	const {account, help, refreshing, setRefreshing, navigation, styles} = props;
	const store = useStore();
	
	// The selected item value along with the related section, needed to get the correct data when selecting a help item
	const [selectedItem, setSelectedItem] = useState(null); 	// Either null or 'section.item'
	const [itemSection, setItemSection] = useState(null);
	
	// On mount
	useEffect(() => {
		fetchHelpSections()(store.dispatch);
	}, []);
	
	// On refresh
	useEffect(() => {
		if (refreshing) {
			fetchHelpSections()(store.dispatch);
		}
	}, [refreshing]);
	
	// When help finished loading (e.g. after fetching)
	useEffect(() => {
		if (!help.loading) {
			setRefreshing(false);
		}
	}, [help.loading]);
	
	function handleSelectedItem(section, item) {
		if (item) {
			setItemSection(section);
			setSelectedItem(item);
		}
		else {
			setSelectedItem(null);
			setItemSection(null);
		}
	}
	
	function backAction() {
		if (account?.loggedIn && account?.id) {
			navigation.navigate('homeScreen');
		}
		else {
			navigation.navigate('aboutScreen')
		}
	}
	
	if (help.loading) {
		return <Spinner title={t("Loading help sections")} subTitle={t("Please wait...")} styles={styles} />
	}
	else if (!selectedItem) {
		// Return list with all section groups
		return (
			<>
				{Object.keys(help.data).map(section => (
					<HelpItemsGroup 
						key={section} 
						data={help.data[section]} 
						setSelected={(item) => handleSelectedItem(section, item)} 
						styles={styles} 
					/>
				))}
				<Button mode={'contained'} onPress={backAction}>{t('Back')}</Button>
			</>
		)
	}
	else {
		// Return selected item content
		return (
			<HelpSection 
				data={help.data[itemSection]?.items[selectedItem] ?? {}} 
				setSelected={(item) => handleSelectedItem(itemSection, item)} 
			/>
		)
	}
}
HelpScreen.propTypes = {
	account: PropTypes.object.isRequired,
	help: PropTypes.object.isRequired,
	refreshing: PropTypes.bool.isRequired,
	setRefreshing: PropTypes.func.isRequired,
	styles: PropTypes.object.isRequired,
	navigation: PropTypes.object.isRequired,
}

export default HelpScreen