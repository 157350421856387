import * as React from 'react';
import { Platform, StyleSheet } from 'react-native';

// Icon Libraries
import { Entypo, FontAwesome, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

// Colours
import Colors from './Colors';

// Entypo Icon
export function EntypoIcon(props) {
	return (
		<Entypo name={props.icon} size={(props.size ? props.size : 30)} style={[{marginBottom: -3}, props.style]} color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
	);
}

// FontAwesome Icon
export function FontAwesomeIcon(props) {
	return (
		<FontAwesome name={props.icon} size={(props.size ? props.size : 30)} style={[{marginBottom: -3}, props.style]} color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
	);
}

// Ion Icon
export function IonIcon(props) {
	// Style based on device
	var prefix = "";
	switch (Platform.OS) {
		case "web":
			prefix += "md";
			break;
			
		default:
			prefix += Platform.OS;
			break;
	}
	var icon = prefix + "-" + props.icon;
	
	return (
		<Ionicons name={icon} size={(props.size ? props.size : 30)} style={[iconStyles.marginBottom, props.style]} color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} />
	);
}

// MaterialCommunityIcons Icon
export function MaterialCommunityIcon(props) {
	return (
		<MaterialCommunityIcons name={props.icon} size={(props.size ? props.size : 30)} style={[iconStyles.marginBottom, props.style]} color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault} {...otherProps(props)} />
	);
}

function otherProps(props) {
	var allowedProps = ['onPress'];
	var _otherProps = {};
	for (var key in props) {
		if (allowedProps.includes(key)) {
			_otherProps[key] = props[key];
		}
	}
	return _otherProps;
}

/* Define Common Icons */
const BackIcon = (style) => (
	<Icon {...style} name='arrow-back' />
);

// Styles
const iconStyles = StyleSheet.create({
		marginBottom: {
			marginBottom: -3
		}
	});