export function prepareData(docType, values = {}, asArray = false) {
    const date = new Date();
    let data = {
        docType,
        timestamp: parseInt(date.getTime() / 1000)    // timestamp in seconds
    };

    if (!asArray) {
        data._id = docType + (values.name ? '_' + values.name : '');
    }

    switch (docType) {
        default:
            data = {...data, ...values};
            break;
            
        case 'registerData':
            /* Build an array of objects with the following structure:
                [
                    {_id: ..., docType: 'registerData', name_: details, details: {driverDetails: ...}},
                    {_id: ..., docType: 'registerData', name_: 'image1', image1: {value: {uri: ...}}},
                    {_id: ..., docType: 'registerData', name_: 'image2', image2: {value: {uri: ...}}},
                ]
            */
           data = {};
           data.details = {docType, name_: 'details'};
           
            Object.keys(values).forEach(screenName => {
                const screen = values[screenName];
                if (['questionnaireScreen', 'companyDetailsScreen'].includes(screenName)) {
                    data[screenName] = {docType, name_: screenName, ...screen};
                }
                else {
                    Object.keys(screen).forEach(propertyName => {
                        const property = screen[propertyName];
                        
                        if (typeof (property) == 'object') {
                            data[propertyName] = {docType, name_: propertyName, [propertyName]: property};
                        }
                        else {
                            data.details[propertyName] = property;
                        }
                    })
                }
            });
            return Object.keys(data).map(key => data[key]);

        case 'login':
            if (!values?.loginForm?.email || !values?.loginForm?.password) {return false;}
            data = {...data, ...values.loginForm}
            break;
    }

    return asArray ? [data] : data;
}