const tintColor = '#2f95dc';

export default {
	tintColor,
	tabIconDefault: '#999',
	//tabIconDefault: '#ccc',
	tabIconSelected: '#fff',
	tabBar: '#fefefe',
	errorBackground: 'red',
	errorText: '#fff',
	warningBackground: '#EAEB5E',
	warningText: '#666804',
	noticeBackground: tintColor,
	noticeText: '#fff',
	/* XDP Colours */
	xdpBlue: '#00bfff',
	xdpYellow: '#fcb712',
	/* More Colours */
	white: '#fff',
	offWhite: '#f1f1f1',
	darkGrey: '#2a2a2a',
	midGrey: '#8e8e93',	
	lightGrey: '#616161',
	/* Icon Colours */
	errorIcon: '#721c24',
	successIcon: '#155724',
	informationIcon: '#004085'
};