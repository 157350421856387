/**
 * Returns an object with the screen and related parameters.
 * 
 * @returns {{screen: String|Boolean, params: Object}}
 */
export function useRoute() {
	if (!window?.location) {
		return false;
	}
	
	// Create object from url (e.g. driverx.local/?screen=registerScreen)
	var route = window.location.href;
	
	var query = route.split('?')[1] ?? false;
	if (query) {
		route = {screen: false, params: {}};
		
		// Set the route screen and params
		query.split(',').forEach(string => {
			const keyWithValue = string.split('=');
			console.log('Key with value', keyWithValue);
			
			if (keyWithValue.length == 2) {
				switch (keyWithValue[0]) {
					case 'screen':
						route[keyWithValue[0]] = keyWithValue[1];
						break;
				
					default:
						route.params[keyWithValue[0]] = isNaN(keyWithValue[1]) ? keyWithValue[1] : Number.parseInt(keyWithValue[1]);
						break;
				}
			}
		});
		
		return route;
	}
	
	return {
		screen: false,
		params: {}
	};
}