// Dependencies
import {combineReducers} from 'redux';

// Reducers
import account from './accountReducer';
import app from './appReducer';
import configuration from './configurationReducer';
import help from './helpReducer';

// Combine reducers
const rootReducer = combineReducers({
    account,
    app,
    configuration,
    help
});

export default rootReducer;