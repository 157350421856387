import React, {useState} from 'react';
import getEnv from '../../config';

// Common Components
import {Button, Card, List, Text, View, useTheme} from "../../components/Common";

// Components
import { Alert, CustomDialog, CustomTextInput, CustomButton, Spinner, Validation } from '../../components';

// Translation
import t from '../../translation';

// Utils
import {preparedFetch} from '../../utils/Http';

// Form
import FormGenerator from '../../components/PaperFormGenerator';
import FormSchema from '../../forms/LoginForm';

const ENV = getEnv();

const LoginFail = (props) => {
	const {account} = props;
	const {colors} = useTheme();
	
	return (
		<List.Section>
			{(typeof account.errors === 'string') && (
				<List.Item
					description={t(account.errors)}
					descriptionNumberOfLines={3}
					left={() => <List.Icon color={colors.error} icon="alert-circle-outline" />}
				/>
			)}

			{(typeof account.errors === 'object') && Object.keys(account.errors).map(key => (
				((typeof account.errors[key] === 'object') ?
					Object.keys(account.errors[key]).map(title => (
						<Alert key={key} type="error" title={t(title)} message={t(account.errors[key][title])} />
					))
				:
					<Alert key={key} type="error" title={t(account.errors[key])} />
				)
			))}
		</List.Section>
	);
};

const LoginScreen = (props) => {
	const {account, app, configuration, loginHandler, navigation, styles} = props;
	const {baseStyles, buttonStyles} = styles;
	const [forgotPassword, setForgotPassword] = useState(false);

	return (
		<View style={{alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}}>
			{	/* Login failure */
				(account.loginStatus == 'error') && <LoginFail {...props} />
			}
	
			{	/* Awaiting approval / track application */ }
			{account?.loggedIn ? (
				<Card style={{marginBottom: 20}}>
					<Card.Title
						title={t('Application submitted')}
						subtitle={t('Track the progress of your application:')}
						style={baseStyles.baseDivider}
					/>
					<Card.Content style={{flex: 1, marginTop: 10}}>
						<Button mode="contained" onPress={() => navigation?.navigate('registeredScreen')} style={buttonStyles.default}>{t('Application Updates')}</Button>
					</Card.Content>
				</Card>
			) : (account.loginAttempt ? (
				<Spinner title={t('Attempting to log in')} style={{flex: 1}} />
			) : (
				<FormGenerator
						schema={app.schemas?.login ?? FormSchema}
						submitHandler={loginHandler}
						validateOnChange={true}
						navigation={navigation} 	// The navigation.navigate() function is used when a screen is not found in the schema.
					>
						<>
							{/* Forgot password */}
							<View style={baseStyles.paddingTop}>
								<Text style={{alignSelf: 'center', paddingBottom: 5}}>{t('Forgot your password')}?</Text>
								<Button mode={"contained"} onPress={() => setForgotPassword(true)}>{t('Reset Password')}</Button>
							</View>
							
							{/* Register */ }
							{account?.loggedIn ? null : (
								<View style={baseStyles.paddingTop}>
									<Text style={{alignSelf: 'center', paddingBottom: 5}}>{t('If you do not currently have an account, you may register')}:</Text>
									<Button mode="contained" onPress={() => navigation?.navigate('registerScreen')}>{t('Register')}</Button>
								</View>
							)}
						</>
					</FormGenerator>
			))}
			
			{/* Forgot password dialog */}
			<ForgotPasswordDialog app={app} styles={styles} configuration={configuration} dialogVisible={forgotPassword} toggleDialog={() => setForgotPassword(!forgotPassword)} />
		</View>
	);
};


const ForgotPasswordDialog = (props) => {
	const {app, configuration, dialogVisible, styles, toggleDialog} = props;
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const title = t('Reset Password');
	const description = t('Enter email to receive link with instructions.');
	const theme = useTheme();
	const emailValidation = [
		['string'],
		['email', 'Please enter your email address'],
		['required', 'Email is required']
	];
	
	async function handleReset(action, data = {})
	{
		try {
			switch (action) {
				case 'submit':
					if (data.email) {
						var res = await preparedFetch(`${ENV.defaultRestServer}/account/forgotpassword`, {
							method: 'POST',
							body: data
						});
						
						res = await res.json();
						
						if (res.status == 'success') {
							setError(false);
							setSuccess(res.alert ?? `Reset link has been sent to ${data.email}`)
						}
						else {
							setSuccess(false);
							setError(res.reason ?? 'Failed to reset password');
						}
					}
					break;
					
				case 'cancel':
				default:
					toggleDialog()
					break;
			}
			
		} catch (e) {
			console.error(`Failed to reset password: ${e.message ?? ''}`);
			setSuccess(false);
			setError('Failed to reset password');
		}
	}
	
	return (
		<CustomDialog
				title={title}
				initialData={{email: ''}}
				description={description}
				visible={dialogVisible}
				handleDismiss={() => toggleDialog()}
				content={(data, setData) => (
					<>
						{error && (
							<Alert type="error" title={t('Reset Fail')} message={t(error)} />
						)}
						{success && (
							<Alert type="success" title={t('Reset Success')} message={t(success)} />
						)}
						
						<Validation
							validation={emailValidation} 
							name={'email'} 
							value={data.email}
							language={configuration?.settings?.language?.value ?? 'en'}
							translations={app?.translations ?? {}}
							style={styles.baseStyles.marginTop}
						>
							<CustomTextInput
								name={'email'}
								value={data.email}
								label={'Email'}
								tooltip={false}
								visibleTooltip={false}
								theme={theme}
								styles={styles}
								onChangeText={(newValue) => {setData({email: newValue})}}
								autoCapitalize={'none'}
								autoCompleteType={'email'}
								keyboardType={'email-address'}
							/>
						</Validation>
					</>
				)}
				actions={(data) => (
					<>
						<CustomButton mode={'contained'} text={t('Cancel')} onPress={() => handleReset('cancel')} />
						<CustomButton mode={'contained'} text={t('Submit')} onPress={() => handleReset('submit', data)} />
					</>
				)}
			/>
	)
}


export default LoginScreen;