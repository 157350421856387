import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

// Import: Components
import {Camera, View, useTheme} from './Common';
import { PermissionsNeededModal, ServicesNeededModal } from './modals';

// Import: Utils
import t from '../translation';

// Styles
import {BaseStyles} from '../constants/Styles';
import {Platform} from 'react-native';


/**
 * Main component for providing permissions.
 * 
 * @param {*} props 
 * @returns 
 */
const PermissionsProvider = (props) => {
	const {serviceChecksInterval} = props;
	const [servicesChecked, setServicesChecked] = useState(false);
	const [servicesEnabled, setServicesEnabled] = useState(false);
	const [permissionsGranted, setPermissionsGranted] = useState(false);
	const [ready, setReady] = useState(false);
	const theme = useTheme();
	const baseStyles = BaseStyles(theme);
	const styles = {baseStyles};
	
	// What services are required to be enabled
	const [services, setServices] = useState({});
	
	// What permissions are required to be granted
	const [permissions, setPermissions] = useState({
		camera: {
			title: t('Camera'),
			precondition: Camera.getAvailableCameraTypesAsync,	// Grant permissions automatically if camera is not available
			askFor: Camera.requestCameraPermissionsAsync,
			granted: false
		},
	})
	
	/* 
		Effect runs on mount, currently sets the ready state, and checks that services are enabled (in an interval).
		Services are also checked when the modal "Done" button is pressed.
	*/
	useEffect(() => {
		var checksInterval;
		setReady(true);
		checkServices().then(res => {
			setServicesEnabled(res);
			// Declare services check interval (5s by default)
			if (!checksInterval) {
				checksInterval = setInterval(() => {
					checkServices().then(res => {
						setServicesEnabled(res);
					})
				}, serviceChecksInterval ?? 5000);
			}
		});
		
		// Cleanup on unmount
		return () => {
			clearInterval(checksInterval);
			setReady(false);
		}
	}, []);
	
	/* 
		Effect runs when the servicesChecked state changes, and checks that the required services have been enabled,
		if they haven't then a modal shows with the required services.
	*/
	useEffect(() => {
		if (!servicesChecked) {
			checkServices().then(res => {
				setServicesChecked(true);
				setServicesEnabled(res);
			});
		}
		
		// Cleanup on unmount (revert to defaults);
		return () => {
			Object.keys(services).forEach(key => {
				const service = services[key];
				setServices({...services, [key]: {...service, enabled: false}});
			})
			setServicesEnabled(false);
		}
	}, [servicesChecked]);
	
	// Effect runs when all services have been enabled, to check that all the required permissions are granted
	useEffect(() => {
		if (servicesEnabled) {
			checkPermissions().then(res => {
				// True if there are no false values
				setPermissionsGranted(res);
			})
		}
		
		// Cleanup on unmount
		return () => {
			setPermissionsGranted(false);
		}
	}, [servicesEnabled]);
	
	
	async function checkPermissions(key = false) {
		let _permissions = {};
		
		if (key) {
			_permissions = {...permissions};
			const permission = permissions[key] ?? false;
			
			if (permission && permission.askFor && typeof (permission.askFor) === 'function') {
				// Automatically grant for non-web, or if preconditions fails
				var preconditionPassed = false;
					
				// Check for preconditions
				if (typeof(permission.precondition) === 'function') {
					preconditionPassed = await permission.precondition();
					preconditionPassed = Array.isArray(preconditionPassed) ? preconditionPassed.length !== 0 : preconditionPassed;
				}
				
				// Ask for permission
				const res = await permission.askFor();
				_permissions[key] = {...permission, ...res};
				
				// Automatically grant is precondition fails
				if (!preconditionPassed) {
					_permissions[key] = {...permission, granted: true, canAskAgain: true};
				}
			}
		}
		else {
			for (const key in permissions) {
				const permission = permissions[key];
				
				if (permission.askFor && typeof (permission.askFor) === 'function') {
					// Automatically grant for non-web, or if preconditions fails
					var preconditionPassed = false;
						
					// Check for preconditions
					if (typeof(permission.precondition) === 'function') {
						preconditionPassed = await permission.precondition();
						preconditionPassed = Array.isArray(preconditionPassed) ? preconditionPassed.length !== 0 : preconditionPassed;
					}
					
					// Ask for permission
					const res = await permission.askFor();
					_permissions[key] = {...permission, ...res};
					
					// Automatically grant if precondition fails
					if (!preconditionPassed) {
						_permissions[key] = {...permission, granted: true, canAskAgain: true};
					}
				}
			}
		}
		
		setPermissions(_permissions);
		return !Object.keys(_permissions).map(key => _permissions[key].granted).includes(false);
	}
	
	
	async function checkServices() {
		let _services = {};
		
		// Check each service if it is enabled
		for (const key in services) {
			const service = services[key];
			
			if (service.checkFor && typeof (service.checkFor) === 'function') {
				let res = await service.checkFor();
				res = typeof (res) === 'boolean' ? {enabled: res} : {...res, enabled: true};	// Set enabled: true if unexpected response
				_services[key] = {...service, ...res};
			}
		}
		
		// Update the services state & return boolean (true if all services have been enabled)
		setServices(_services);
		return !Object.keys(_services).map(key => _services[key].enabled).includes(false);
	}
	
	return ready && (
		<View style={[styles.baseStyles.baseContainer]}>
			{/* Services needed modal */}
			<ServicesNeededModal 
				services={services} 
				modalVisible={!servicesEnabled}
				dismissable={false}
			/>
			
			{/* Permissions needed modal */}
			<PermissionsNeededModal 
				checkPermissions={() => {
					checkPermissions().then(res => {
						// True if there are no false values
						setPermissionsGranted(res);
					})
				}}
				permissions={permissions} 
				modalVisible={servicesEnabled && !permissionsGranted} 
			/>
			
			{/* Services enabled and permissions granted, then proceed with app contents */}
			{servicesEnabled && permissionsGranted && (props.children)}
		</View>
	)
}

PermissionsProvider.propTypes = {
	serviceChecksInterval: PropTypes.number,	// Defaults to 5000ms
}

export default PermissionsProvider
