import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import {Button, Card, Divider, List, Paragraph, TextInput, View, useTheme} from '../../components/Common';
import Alert from '../../components/Alert';
import Spinner from '../../components/Spinner';

// Styles
import {BaseStyles} from '../../constants/Styles';

// Translation
import t from '../../translation';


/**
 * Registration successful
 */
const RegisterSuccess = (props) => {
	const theme = useTheme();
	const baseStyles = BaseStyles(theme);
	const {navigation} = props;

	return (
		<Card style={{alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}}>
			<Card.Title
				title={t('Application submitted')}
				subtitle={t('Thank you for registering')}
				style={baseStyles.baseDivider}
			/>
			<Card.Content style={{flex: 1, marginTop: 10}}>
				<Alert type="success" title={t('Application submitted')} message={t('Thank you for registering')} />
				<Paragraph style={{marginBottom: 10}}>{t('An email has been sent to you with the details required for keeping track of the application')}.</Paragraph>
				<Divider style={{marginBottom: 10}} />
				<Paragraph>{t('Please login using the details, sent via email')}.</Paragraph>
				<Button mode="contained" onPress={() => navigation.navigate('loginScreen')}>Login</Button>
			</Card.Content>
		</Card>
	);
}


/**
 * Registration failed
 */
const RegisterFail = (props) => {
	const {account, navigation} = props;
	const {colors} = useTheme();

	return (
		<View style={{alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}}>
			{/* Description */}
			<Alert type="error" title={t('Application registration failed')} />
			<List.Section>
				<List.Subheader>{t('The following errors occurred')}:</List.Subheader>
				{typeof account.errors === 'string' && (
					<List.Item
						description={t(account.errors)}
						descriptionNumberOfLines={3}
						left={() => <List.Icon color={colors.error} icon="alert-circle-outline" />}
					/>
				)}

				{typeof account.errors === 'object' && Object.keys(account.errors).map(key => (
					<List.Item
						key={key}
						description={t(account.errors[key])}
						descriptionNumberOfLines={3}
						left={() => <List.Icon color={colors.error} icon="alert-circle-outline" />}
					/>
				))}
			</List.Section>
			<Button mode="contained" onPress={() => navigation.navigate('registerScreen')}>Back</Button>
		</View>
	)
}


/**
 * Submission in progress
 */
const Submitting = ({styles}) => {
	const {baseStyles} = styles;

	return (
		<View style={[baseStyles.paddedContainer, {alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}]}>
			<Spinner style={{flex: 1}} />
		</View>
	)
}


/**
 * Application progress
 */
const ApplicationProgress = (props) => {
	const {account, fetchAccountData, navigation, refreshing, setRefreshing, styles} = props;
	const {baseStyles, buttonStyles} = styles;
	const {colors} = useTheme();
	const [applicationStatus, setApplicationStatus] = useState({
		submitted: false,
		rejected: false,
		approved: false,
		contractSent: false,
		contractSigned: false,
	});
	var refreshTimeout;

	// On mount
	useEffect(() => {
		fetchAccountData('application', account.application?.id);
	}, []);
	
	// On refresh
	useEffect(() => {
		if (refreshing) {
			fetchAccountData('application', account.application?.id);
			refreshTimeout = setTimeout(() => {setRefreshing(false)}, 2000);
		}
	}, [refreshing])
	
	// When the application gets updated
	useEffect(() => {
		if (account.application) {
			setApplicationStatus({
				submitted: ['submitted', 'approved', 'rejected', 'contract_sent', 'contract_signed'].includes(account.application?.status),
				rejected: account.application?.status == 'rejected',
				approved: ['approved', 'contract_sent', 'contract_signed'].includes(account.application?.status),
				contractSent: ['contract_sent', 'contract_signed'].includes(account.application?.status),
				contractSigned: account.application?.status == 'contract_signed'
			});
			
			// Refresh control
			if (refreshTimeout) {
				clearTimeout(refreshTimeout);
				refreshTimeout = null;
			}
			setRefreshing(false);
		}
	}, [account.application]);

	return (
		<Card style={{flex: 1, alignSelf: 'center', maxWidth: 720, width: '100%', height: '100%'}}>
			<Card.Title
				title={t('Application progress')}
				subtitle={t('Track the progress of your application:')}
				style={baseStyles.baseDivider}
			/>
			<Card.Content style={{flexGrow: 1, marginTop: 10}}>
				<List.Section>
					<List.Item
						title={t('Application Submitted.')}
						left={
							(props) => (
								<List.Icon {...props}
									color={applicationStatus.submitted ? colors.success : null}
									icon={applicationStatus.submitted ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
								/>
							)
						}
					/>
					
					<List.Item
						title={t('Application Rejected.')}
						left={
							(props) => (
								<List.Icon {...props}
									color={applicationStatus.rejected ? colors.error : null}
									icon={applicationStatus.rejected ? 'close-circle-outline' : 'checkbox-blank-circle-outline'}
								/>
							)
						}
					/>
					
					<List.Item
						title={t('Application Approved.')}
						left={
							(props) => (
								<List.Icon {...props}
									color={applicationStatus.approved ? colors.success : null}
									icon={applicationStatus.approved ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
								/>
							)
						}
					/>
					
					<List.Item
						title={t('Operations Manual Sent.')}
						left={
							(props) => (
								<List.Icon {...props}
									color={applicationStatus.approved ? colors.success : null}
									icon={applicationStatus.approved ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
								/>
							)
						}
					/>
					
					<List.Item
						title={t('Agreement Sent.')}
						left={
							(props) => (
								<List.Icon {...props}
									color={applicationStatus.contractSent ? colors.success : null}
									icon={applicationStatus.contractSent ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
								/>
							)
						}
					/>
					
					<List.Item
						title={t('Agreement Signed.')}
						left={
							(props) => (
								<List.Icon {...props}
									color={applicationStatus.contractSigned ? colors.success : null}
									icon={applicationStatus.contractSigned ? 'check-circle-outline' : 'checkbox-blank-circle-outline'}
								/>
							)
						}
					/>
				</List.Section>

				<TextInput
					label={'Note'}
					disabled={true}
					value={account.application?.reason ?? ''}
					multiline={true}
					mode={'outlined'}
					numberOfLines={4}
				/>
			</Card.Content>
			<Card.Actions style={{flexDirection: 'column', justifyContent: 'space-between', alignItems: 'stretch'}}>
				{/* Edit application */}
				{account?.application?.status == 'rejected' && (
					<Button mode={'contained'} color={colors.onSurface} onPress={() => {navigation.navigate('registerScreen', {update: true})}} style={[baseStyles.marginTop, buttonStyles.default]}>{t('Update Application')}</Button>
				)}

				{/* Back button */}
				<Button mode={'contained'} color={colors.onSurface} onPress={() => navigation.goBack()} style={[baseStyles.marginTop, buttonStyles.default]}>{t('Back')}</Button>
			</Card.Actions>
		</Card>
	)
}

ApplicationProgress.propTypes = {
	account: PropTypes.object.isRequired,
	fetchAccountData: PropTypes.func.isRequired,
	refreshing: PropTypes.bool.isRequired,
	setRefreshing: PropTypes.func.isRequired,
	styles: PropTypes.object.isRequired,
}


/**
 * Registered screen
 */
const RegisteredScreen = (props) => {
	const {account, navigation, styles} = props;

	// Check for application id
	if (account?.application?.id) {
		// Show the application progress
		return <ApplicationProgress {...props} />
	}

	// Show screen depending upon application status
	switch (account?.application?.status) {
		case 'submitting':
			return <Submitting {...props} navigation={navigation} />;

		case 'submitted':
			return <RegisterSuccess {...props} navigation={navigation} />;

		case 'error':
			return <RegisterFail {...props} navigation={navigation} />;

		default:
			return null;
	}
};

RegisteredScreen.propTypes = {
	account: PropTypes.object.isRequired,
	refreshing: PropTypes.bool,
	setRefreshing: PropTypes.func.isRequired,
	styles: PropTypes.object.isRequired,
}

export default RegisteredScreen;