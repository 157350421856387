import Constants from 'expo-constants';

const getEnv = () => {
	return __DEV__ ? {
		deviceId: Constants.installationId.toLowerCase(),
		defaultRestServer: 'http://127.0.0.1/driverx/rest',
		couch: {
			server: 'http://127.0.0.1:5984/',
		}
	} : {
		deviceId: Constants.installationId.toLowerCase(),
		defaultRestServer: 'https://xdp.sysx.co.uk/driverx/rest',
		couch: {
			server: 'https://couchdb.xdp.co.uk/',
		}
	}
};

export default getEnv;