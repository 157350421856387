import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

// Styling
import {Platform, StyleSheet} from 'react-native';
import {MaterialCommunityIcons} from "@expo/vector-icons";

// Common Components
import {Animated, Text, View, useTheme} from './Common';

const styles = (theme) => {
    return StyleSheet.create({
        alertWrapper: {
            borderWidth: 2,
            marginTop: 8,
            marginBottom: 4,
            borderRadius: 5,
            display: 'flex',
            alignItems: 'center',
            flexBasis: 'auto'
        },

        column: {
			flex: 1,
            flexDirection: 'column',
			padding: 10,
        },
		
        row: {
			flex: 0,
            flexDirection: 'row'
        },
		
		message: {
			marginTop: 5,
			marginLeft: 10,
			marginBottom: 8
		},

        errorWrapper: {
            borderColor: theme.colors.errors.dark,
            backgroundColor: theme.colors.errors.light,
            color: theme.colors.errors.dark
        },
        errorTitle: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginRight: 8,
            color: theme.colors.errors.dark
        },
        errorMessage: {
            marginRight: 8,
            color: theme.colors.errors.dark
        },
        errorIcon: {
            marginRight: 8,
            color: theme.colors.errors.dark
        },

        warningWrapper: {
            borderColor: theme.colors.warnings.dark,
            backgroundColor: theme.colors.warnings.light,
            color: theme.colors.warnings.dark
        },
        warningTitle: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginRight: 8,
            color: theme.colors.warnings.dark
        },
        warningMessage: {
            marginRight: 8,
            color: theme.colors.warnings.dark
        },
        warningIcon: {
            marginRight: 8,
            color: theme.colors.warnings.dark
        },

        successWrapper: {
            borderColor: theme.colors.successes.dark,
            backgroundColor: theme.colors.successes.light,
            color: theme.colors.successes.dark
        },
        successTitle: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginRight: 8,
            color: theme.colors.successes.dark
        },
        successMessage: {
            marginRight: 8,
            color: theme.colors.successes.dark
        },
        successIcon: {
            marginRight: 8,
            color: theme.colors.successes.dark
        },
		
        infoWrapper: {
            borderColor: theme.colors.infos.dark,
            backgroundColor: theme.colors.infos.light,
            color: theme.colors.infos.dark
        },
        infoTitle: {
            textTransform: 'uppercase',
            fontWeight: 'bold',
            marginRight: 8,
            color: theme.colors.infos.dark
        },
        infoMessage: {
            marginRight: 8,
            color: theme.colors.infos.dark
        },
        infoIcon: {
            marginRight: 8,
            color: theme.colors.infos.dark
        }		
    });
};


/**
 * Alert component uses icon, title, message and type to return an alert view.
 * Types: error/warning/success
 * Icons used: MaterialIcons
 *
 * @param icon
 * @param title
 * @param message
 * @param type
 * @returns {JSX.Element}
 * @constructor
 */
const Alert = ({icon, title, message, type, style, ...rest}) => {
    const animations = {
        shake: {
            ref: useRef(new Animated.Value(0)).current, // Shake animation ref, start with margin left 0
            start: shake,
            style: false    // This is populated by the validAnimation method
        }
    };
    const [animation, setAnimation] = useState(rest.animation ?? false);
    const [ready, setReady] = useState(false);
    
    // Set icons based on the type of the alert
	if (!icon) {
		switch (type) {
			case 'info':
			case 'warning':
				icon = "information-outline";
				break;

			case 'error':
				icon = "alert-outline";
				break;

			case 'success':
				icon = "check-circle-outline";
				break;

			default:
				icon = type;
		}
	}
    
    // Effect runs on mount
    useEffect(() => {
        setAnimation(validAnimation());
        setReady(true);
    }, []);
    
    // Effect runs if the animation is set
    useEffect(() => {
        if (animation && typeof (animation) === 'object') {
            animation.start(animation.ref);
        }
    }, [animation]);

	const theme = useTheme();
    const useStyles = styles(theme);
    //console.log(theme.colors);
    
    // Ensure that the specified animation exists and has the correct properties
    function validAnimation() {
        if (animation && typeof (animations[animation]) !== 'undefined') {
            const _animation = animations[animation];
            if (typeof(_animation.start) === 'function' && typeof(_animation.ref) !== 'undefined') {
                return {..._animation, style: animationStyle(animation, _animation.ref)};
            }
        }
        return false;
    }
    
    // Getter for the related animation style, requires the name and the animation reference
    function animationStyle(name, animationRef) {
        switch (name) {
            case 'shake':
                return {marginLeft: animationRef};
        
            default:
                return {};
        }
    }
    
    // Function called programatically on mount based on the specified "animation"
    function shake(ref) {
        // Define the shake sequence, and start the animation
        let sequence = [-10, 10, -5, 5, 0];
        sequence = sequence.map(value => Animated.timing(ref, {duration: 72, toValue: value, useNativeDriver: false}));
        Animated.sequence(sequence).start();
    }

    return ready && (
        <>
            {animation && (
                 <Animated.View style={[useStyles.alertWrapper, useStyles.row, useStyles[type + 'Wrapper'], style, animation.style]}>
                    <View style={[useStyles.column]}>
                         <View style={useStyles.row}>
                             <MaterialCommunityIcons testID={'alert_icon'} name={icon} style={useStyles[type + 'Icon']} size={22} />
                             <Text testID={'alert_title'} style={[useStyles[type + 'Title'], {paddingTop: 5}]}>{title}</Text>
                         </View>
                         {message && (
                             <View style={[useStyles.row, useStyles.message]}>
                                 <Text testID={'alert_message'} style={useStyles[type + 'Message']}>{message}</Text>
                             </View>
                         )}
                     </View>
                </Animated.View>
            )}
            
            {!animation && (
                 <View style={[useStyles.alertWrapper, useStyles.row, useStyles[type + 'Wrapper'], style]}>
                    <View style={[useStyles.column]}>
                        <View style={useStyles.row}>
                            <MaterialCommunityIcons testID={'alert_icon'} name={icon} style={useStyles[type + 'Icon']} size={22} />
                            <Text testID={'alert_title'} style={[useStyles[type + 'Title'], {paddingTop: 5}]}>{title}</Text>
                        </View>
                        {message && (
                            <View style={[useStyles.row, useStyles.message]}>
                                <Text testID={'alert_message'} style={useStyles[type + 'Message']}>{message}</Text>
                            </View>
                        )}
                    </View>
                </View>
            )}
        </>
    );
};


Alert.propTypes = {
    icon: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
	theme: PropTypes.object,
    animation: PropTypes.oneOf(['shake'])
};

export default Alert;