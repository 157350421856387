import WebSqlPouchCore from 'pouchdb-adapter-websql-core'
var pouchSQLite = null;

function openSQLiteDB(opts) {
    // The SQLite Plugin started deviating pretty heavily from the standard openDatabase() function, as they started adding more features.
    // It's better to just use their "new" format and pass in a big options object.
	// Also there are many options here that may come from the PouchDB constructor, so we have to grab those.
	return function(name, version, description, size) {
						var openOpts = Object.assign({}, opts, {
											name: name,
											version: version,
											description: description,
											size: size
										});

						// There is no onError callback on openDatabase
						function onSuccess(res) {
							console.log(`Opened sqlite DB: ${res._db?._name ?? ''}`);
							if (typeof opts.onSuccess === 'function') {
								opts.onSuccess(res);
							}
						};

						return pouchSQLite.openDatabase(openOpts.name, openOpts.version, openOpts.description, openOpts.size, onSuccess);
				};
}

function ReactNativeSQLitePouch(opts, callback) {
	var webSql = openSQLiteDB(opts);
	var _opts = Object.assign({websql: webSql}, opts);
	WebSqlPouchCore.call(this, _opts, callback);
}

// No need for a prefix in ReactNative (i.e. no need for `_pouch_` prefix)
ReactNativeSQLitePouch.use_prefix = false;
ReactNativeSQLitePouch.valid = function () {
	return true;
};

function reactNativeSQLitePlugin(PouchDB) {
	PouchDB.adapter('react-native-sqlite', ReactNativeSQLitePouch, true);
}

export default function createPlugin(SQLite) {
	pouchSQLite = SQLite;
	return reactNativeSQLitePlugin;
}