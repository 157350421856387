import Constants from 'expo-constants';

// Import: Action types
import {
    FETCH_APPLICATION,
    APPLICATION_UPDATED,
    ATTEMPT_LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_ACCOUNT,
    LOGOUT_FAIL,
    REGISTER_APPLICATION,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    ACCOUNT_FETCH_FAIL,
    ACCOUNT_FETCH_ATTEMPT,
} from './types';

// Import: PouchDB
import {defaultDB} from '../../pouchdb/PouchDB';

// Import: Utils
import {preparedFetch} from '../../utils/Http';
import {prepareData} from '../../utils/Pouch';

// Import: config
import getEnv from '../../config';
const ENV = getEnv();


export const fetchAccountData = (name, applicationId = false, accountId = false) => async (dispatch) => {
    defaultDB.upsert('fetchrequest:' + name, (doc) => {
        return {...doc, ...prepareData('fetchrequest', {name, applicationId, accountId})};
    }).then((res) => {
         // Update the login attempt state
         dispatch({
            type: ACCOUNT_FETCH_ATTEMPT,
        });
    }).catch((err) => {
        console.error('Failed to fetch [' + name + '] account data: ' + (err.message ?? ''));
        return false;
    });
}


export const fetchAccountDataFail = (document) => async (dispatch) => {
    const {payload} = document;

    defaultDB
        .get(document._id)
        .then(function (doc) {
            return defaultDB.remove(doc);
        })
        .then(function () {
            dispatch({
                type: ACCOUNT_FETCH_FAIL,
                payload: {
                    errors: payload.errors
                }
            })
        })
        .catch(function (err) {
            console.error('Fetch failed: ' + (err.message ?? ''));
            return false;
        });
}


export const applicationLoaded = (document) => async (dispatch) => {
    const {payload} = document;

    defaultDB
        .get(document._id)
        .then(function (doc) {
            return defaultDB.remove(doc);
        })
        .then(function () {
            dispatch({
                type: FETCH_APPLICATION,
                payload
            })
        })
        .catch(function (err) {
            console.error('Fetch failed: ' + (err.message ?? ''));
            return false;
        });
}


export const applicationUpdated = (document) => async (dispatch) => {
    const {payload} = document;

    defaultDB
        .get(document._id)
        .then(function (doc) {
            return defaultDB.remove(doc);
        })
        .then(function () {
            dispatch({
                type: APPLICATION_UPDATED,
                payload: {application: payload.application, ...payload.account}
            })
        })
        .catch(function (err) {
            console.error('Failed to update application: ' + (err.message ?? ''));
            return false;
        });
}


/**
 * Action used to attempt login by saving a "login" type couchDB document, with the related credentials.
 * 
 * @param {string} values   :The object with the email and password 
 * @returns 
 */
export const login = (values, url = ENV.defaultRestServer) => async (dispatch) => {
    try {
        // Set the login attempt state
        dispatch({
            type: ATTEMPT_LOGIN,
        });
        
        // Check that email and password are set
        if (!values?.loginForm?.email || !values?.loginForm?.password) {
            throw new Error('Missing required email/password');
        }
        
        // Prepare login event data
        const data = prepareData('login', values);
        if (!data) {
            throw new Error('Could not prepare login data');
        }
        
        // Send post request to server to record log out event
        const userAgent = await Constants.getWebViewUserAgentAsync();
        var response = await preparedFetch(`${url}/account/login`, {
            method: 'POST',
            body: {...data, userAgent}
        });
        response = await response.json();
        
        if (response.status && response.status === 'success') {
            defaultDB.get('token').catch(err => {
                // Store the authenticated token only if not set (does not change)
                if (err.name == 'not_found') {
                    return defaultDB.put(prepareData('token', {value: response.payload.token ?? false}));
                }
            }).finally(res => {
                // Handle login success
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: response.payload
                });
            });
        }
        else {
            // Handle login fail
            dispatch({
                type: LOGIN_FAIL,
                payload: {
                    loginStatus: 'error',
                    errors: response.errors // This can be an array, otherwise we could had just thrown the error
                }
            })
        }
    } 
    catch (err) {
        console.error(`Failed to log in: ${err.message ?? ''}`);
        dispatch({
            type: LOGIN_FAIL,
            payload: {
                loginStatus: 'error',
                errors: `Failed to log in : ${err.message ?? ''}`
            }
        })
    }
}


/**
 * Action used to log out device, also informs the server of logout, and removes the "token" couchDB document.
 */
export const logout = (url = ENV.defaultRestServer) => async (dispatch) => {
    try {
        const data = prepareData('logout');
        if (!data) {
            throw new Error('Could not prepare logout data');
        }
        
        var response = await preparedFetch(`${url}/account/logout`, {
            method: 'POST',
            body: data
        });
        response = await response.json();
        
        if (response.status && response.status === 'success') {
            defaultDB
                .remove({_id: 'token'})
                .catch(err => true)
                .finally(res => {
                    dispatch({
                        type: LOGOUT_ACCOUNT
                    });
                });
        }
        else {
            throw new Error(response.reason ?? '');
        }
    } catch (err) {
        console.error(`Failed to log out: ${err.message ?? ''}`);
        dispatch({
            type: LOGOUT_FAIL
        });
    }
}


/**
 * Function used to submit the onboarding application form data to the server for processing.
 * 
 * @param {Object} values 
 * @param {String} applicationId    :Used when updating the application (default: false) 
 * @returns 
 */
export const register = (values, applicationId = false, url = ENV.defaultRestServer) => async (dispatch) => {
    
    // Prepare and send registration data to server
    const data = prepareData('registerData', values);
    try {
        // Set application status to 'submitting'
        dispatch({
            type: REGISTER_APPLICATION,
            payload: {
                application: {
                    status: 'submitting'
                }
            }
        });
        
        // Send registration data
        var response = await preparedFetch(url + '/account/register', {
            method: 'POST',
            body: {data, applicationId}
        });
        response = await response.json();
        
        // Handle response
        if (response.status && response.status === 'success') {
            // Set application status to 'submitted'
            dispatch({
                type: REGISTER_SUCCESS,
                payload: {
                    application: {
                        status: 'submitted'
                    }
                }
            });
        }
        else {
            // Set application status to 'error'
            dispatch({
                type: REGISTER_FAIL,
                payload: {
                    application: {
                        status: 'error',
                    },
                    errors: response.errors ?? []
                }
            })
        }
    } catch (err) {
        console.error(`Failed to register: ${err.message ?? ''}`);
    }
}


// *Moved to utils
// function prepareData(docType, values = {}, asArray = false) {
//     const date = new Date();
//     let data = {
//         docType,
//         timestamp: parseInt(date.getTime() / 1000)    // timestamp in seconds
//     };

//     if (!asArray) {
//         data._id = docType + (values.name ? '_' + values.name : '');
//     }

//     switch (docType) {
//         default:
//             data = {...data, ...values};
//             break;
            
//         case 'registerData':
//             /* Build an array of objects with the following structure:
//                 [
//                     {_id: ..., docType: 'registerData', name_: details, details: {driverDetails: ...}},
//                     {_id: ..., docType: 'registerData', name_: 'image1', image1: {value: {uri: ...}}},
//                     {_id: ..., docType: 'registerData', name_: 'image2', image2: {value: {uri: ...}}},
//                 ]
//             */
//            data = {};
//            data.details = {docType, name_: 'details'};
           
//             Object.keys(values).forEach(screenName => {
//                 const screen = values[screenName];
//                 if (['questionnaireScreen', 'companyDetailsScreen'].includes(screenName)) {
//                     data[screenName] = {docType, name_: screenName, ...screen};
//                 }
//                 else {
//                     Object.keys(screen).forEach(propertyName => {
//                         const property = screen[propertyName];
                        
//                         if (typeof (property) == 'object') {
//                             data[propertyName] = {docType, name_: propertyName, [propertyName]: property};
//                         }
//                         else {
//                             data.details[propertyName] = property;
//                         }
//                     })
//                 }
//             });
//             return Object.keys(data).map(key => data[key]);

//         case 'login':
//             if (!values?.loginForm?.email || !values?.loginForm?.password) {return false;}
//             data = {...data, ...values.loginForm}
//             break;
//     }

//     return asArray ? [data] : data;
// }