import {StyleSheet} from 'react-native';
import Colors from './Colors';
import {height} from './Layout';

export const BaseStyles = (theme) => {
    return StyleSheet.create({
        /* Basic Page Styling */
        baseContainer: {
            backgroundColor: theme?.dark ? Colors.darkGrey : Colors.offWhite,
            flex: 1,
        },
        basicContainer: {
            backgroundColor: 'transparent',
            flex: 1,
        },
        paddedContainer: {
            backgroundColor: 'transparent',
            margin: 15,
            flex: 1,
        },
        backgroundImageContainer: {
            backgroundColor: theme?.dark ? Colors.darkGrey : Colors.offWhite,
            flex: 1,
            maxHeight: height - 56 /* Fix scroll on Browser */,
        },
        basicImageContainer: {
            backgroundColor: theme?.dark ? Colors.darkGrey : Colors.offWhite,
            flex: 1,
        },
        borderedImageContainer: {
            backgroundColor: theme?.dark ? Colors.darkGrey : Colors.offWhite,
            flex: 1,
            borderWidth: 1,
            borderRadius: 5,
            borderColor: Colors.xdpBlue,
        },
        backgroundImage: {
            top: undefined,
            left: undefined,
            height: 320,
            width: 480,
        },

        row: {
            /* flex: 1, */
            flexDirection: 'row',
        },
        spacedRow: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        center: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        centeredRow: {
            flexDirection: 'row',
            alignItems: 'center',
        },

		column: {
			flexDirection: 'column'
		},
        
        /* Signature Pad */
        signaturePad: {
            backgroundColor: theme.light ? theme.surface : '#fff'
        },

        /* Modal Styling */
        // NOTE: backdrop is controlled via Theme
        modalStyle: {
            borderColor: Colors.midGrey /*Colors.xdpBlue,*/,
            borderWidth: 1,
            borderRadius: 5,
        },

        uppercaseYellow: {
            color: Colors.xdpYellow,
            textTransform: 'uppercase',
        },

        /* Help info */
        helpContainer: {
            margin: 10,
        },
        helpText: {
            fontSize: 12,
            color: Colors.midGrey,
        },

        /* Font Sizing */
        smallFont: {
            fontSize: 12,
        },
        tinyFont: {
            fontSize: 10,
        },

        paddingTop: {
            paddingTop: 15,
        },

        marginTop: {
            marginTop: 15,
        },
        marginBottom: {
            marginBottom: 15,
        },

        /* Divider */
        baseDivider: {
            borderColor: theme?.dark ? '#222' : '#eee',
            borderBottomWidth: 1,
        },

		/* Slider */
		slider: {
			marginTop: 15,
			marginBottom: 15
		}

        //hiddenFont: Store.hiddenText() ? { fontFamily:  Store.hiddenText() } : {}
    });
};
const baseStyles = BaseStyles(false);

/* Form Theming; because theme overrides styling on TextInput components */
export const FormTheme = {
    colors: {
        primary: Colors.lightGrey,
        background: '#2a2a2a00',
        text: Colors.white,
        placeholder: Colors.xdpBlue,
    },
};

/* Form Styles */
export const FormStyles = (theme) => {
    return StyleSheet.create({
        xdpImageContainer: {},
        xdpImage: {
            alignSelf: 'center',
            width: 96,
            height: 96,
            margin: 10,
        },
        form: {
            backgroundColor: Colors.lightGrey + (theme.dark ? '33' : '16'), // 33 = 20% transparency
            borderColor: Colors.lightGrey + (theme.dark ? '66' : '33'),
            borderWidth: 1,
            borderRadius: 5,
            //margin: 10,
            padding: 10,
            flex: 1,
        },
        input: {
            marginBottom: 10,
        },
        button: {
            backgroundColor: Colors.xdpBlue,
        },
        additionalButtons: {
            margin: 10,
        },
        additionalButton: {
            marginBottom: 10,
        },
    });
};

/* Error Styles; colours from BootStrap */
export const errorStyles = {
    errorContainer: {
        backgroundColor: '#f8d7da',
        borderColor: '#f5c6cb',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
    },
    errorRow: {
        flexDirection: 'row',
    },
    errorIcon: {
        marginRight: 10,
    },
    errorTitle: {
        marginBottom: 10,
    },
    errorText: {
        ...baseStyles.smallFont,
        color: '#721c24',
    },
    errorBullet: {
        fontSize: 10,
        paddingTop: 5,
        marginLeft: 3,
        marginRight: 15,
        marginBottom: 5,
    },
};
export const ErrorStyles = StyleSheet.create(errorStyles);

/* Success Styles; colours from BootStrap */
export const successStyles = {
    successContainer: {
        backgroundColor: '#d4edda',
        borderColor: '#c3e6cb',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
    },
    successRow: {
        flexDirection: 'row',
    },
    successIcon: {
        marginRight: 10,
    },
    successText: {
        ...baseStyles.smallFont,
        color: '#155724',
    },
};
export const SuccessStyles = StyleSheet.create(successStyles);

/* Information Styles; colours from BootStrap */
export const informationStyles = {
    informationContainer: {
        backgroundColor: '#cce5ff',
        borderColor: '#b8daff',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
    },
    informationRow: {
        flexDirection: 'row',
    },
    informationIcon: {
        marginRight: 10,
    },
    informationText: {
        ...baseStyles.smallFont,
        color: '#004085',
    },
};
export const InformationStyles = StyleSheet.create(informationStyles);

/* Button Styles */
export const ButtonStyles = (theme) => {
    var {padding, ...info} = informationStyles.informationContainer;
    var {padding, ...success} = successStyles.successContainer;
    var {padding, ...error} = errorStyles.errorContainer;

    return StyleSheet.create({
        default: {
            backgroundColor: '#00aced',
            borderWidth: 1,
            borderColor: '#3399ff',
            borderRadius: 5,
        },

		iconButton: {
			// Icon buttons styling
		},

        info: {
            color: informationStyles.informationText.color,
            ...info,
        },

        success: {
            color: successStyles.successText.color,
            ...success,
        },

        warning: {},

        error: {
            color: errorStyles.errorText.color,
            ...error,
        },

        marginBottom: {
            marginBottom: 15,
        },

        noBorder: {
            borderWidth: 0,
        },

        noBackground: {
            backgroundColor: 'transparent',
        },
    });
};
