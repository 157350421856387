import 'react-native-get-random-values';

import React, {useState, useEffect} from 'react';
import {AppState} from 'react-native';
import Constants from 'expo-constants';

// Styles and themes
import {defaultThemeDark, defaultTheme} from './themes';

// Redux
import {localStore} from './redux/store';
import {PersistGate} from 'redux-persist/integration/react';
import {fetchAllSchemas, fetchTranslations} from './redux/actions/appActions';

// Database
import {cleanDB} from "./pouchdb/PouchDB";
import {remoteSync} from "./pouchdb/Sync";

// Stacks
import MainRouter from './MainRouter.js';

// Components
import {PermissionsProvider} from './components';
import {Spinner} from './components';
import {Provider, PaperProvider, View} from './components/Common';
import {StatusBar} from 'expo-status-bar';

export default function App() {

	// Database ready
	const [database, setDatabase] = useState(false);
	const [theme, setTheme] = useState(defaultThemeDark);

	// Changing App state
	const [appReady, setAppReady] = useState(false);
	const [appState, setAppState] = useState(AppState.currentState);
	
	// Effect runs on mount, and sets a clean database, along with the app change event listener
	useEffect(() => {
		AppState.addEventListener('change', changeAppState);
		
		setDatabase(false);
		cleanDB().then(({ready, database}) => {
			if (ready) {
				setDatabase(database);
			} else {
				setDatabase(false);
			}
		});
		
		return () => {
			AppState.removeEventListener('change', changeAppState);
		};
	}, [])

	// Effect runs when the database is set and starts the couch db remote sync process, checks for services and permissions, and loads initial data
	useEffect(() => {
		if (database) {
			// Enable sync once database is ready
			remoteSync(database.db, database.store, database.localStorage);
			
			// Load initial data
			loadInitialData().then(() => {
				setAppReady(true)
			}).catch(({message}) => {
				console.error(`Failed to load initial data: ${message ?? ''}`);
				setAppReady(true);
			});
		}
	}, [database]);
		
	function loadInitialData() {	
		return new Promise(async (resolve, reject) => {
			if (database && database.store) {
				let store = database.store;
				
				// Load the form schemas
				await fetchAllSchemas()(store.dispatch);
				
				// Load translations
				await fetchTranslations()(store.dispatch);
				
				resolve(true);
			}
			
			reject({message: 'Missing database.store'})
		});
	}
		
	function changeAppState(newState) {
		setAppState(newState);
	}
	
	if (!database || !appReady) {
		return (
			<Provider store={localStore}>
				<PaperProvider theme={theme}>
					<View style={{flex: 1, backgroundColor: theme.colors.primary}}>
						<Spinner title="Preparing application" subTitle="Please Wait..." style={{flex: 1}} />
					</View>
					
					<StatusBar
						style={theme.dark ? 'light' : 'dark'}
						backgroundColor={theme.colors.primary}
					/>
				</PaperProvider>
			</Provider>
		)
	}
	
	console.log('Application starting on deviceId: ' + Constants.installationId);
	
	return (
		<Provider store={database.store}>
			<PersistGate loading={null} persistor={database.persistedStore}>
				<PaperProvider theme={theme}>
					<PermissionsProvider>
						<MainRouter 
							onDarkModeToggle={(darkMode) => setTheme(darkMode ? defaultThemeDark : defaultTheme)}
						/>
					</PermissionsProvider>
					<StatusBar
						style={theme.dark ? 'light' : 'dark'}
						backgroundColor={theme.colors.primary}
					/>
				</PaperProvider>
			</PersistGate>
		</Provider>
	);
};