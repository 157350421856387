import Constants from 'expo-constants';
// Types
import {
	CONFIGURATION_REMOTE_UPDATE,
	FETCH_SETTINGS,
} from '../actions/types';

// Initial State
const initialState = {
	collection: 'configuration',
    settings: {
        darkMode: {
			label: 'Dark Mode',
            locked: false,
            name: 'darkMode',
            type: 'boolean',
            value: true,
			visible: true,
			visibleIfLoggedOut: true,
			walkthroughTooltip: {
				placement: 'top',
				onClose: 'language',
				text: 'Use this toggler to disable/enable dark interface'
			},
		},
		language: {
			label: 'Language',
			locked: false,
			name: 'language',
			type: 'select',
			value: 'en',
			visible: true,
			visibleIfLoggedOut: true,
			options: [
				{
					value: 'en',
					name: 'English'
				},
				{
					value: 'ro',
					name: 'Romanian'
				}
			],
			walkthroughTooltip: {
				placement: 'top',
				onClose: false,
				text: 'This selector allows you to change the language of the app (excludes notifications)'
			},
		},
    },
    loading: false,
    error: ''
};

// Configuration reducer
const configuration = (state = initialState, action, debug = Constants?.debugMode ?? false) => {
    const {payload, type} = action;

    switch (type) {
        default:
			//console.log(state);
			return state;
			
		case CONFIGURATION_REMOTE_UPDATE:
			return {
				...payload
			};

        // Fetch the settings from the server
        case FETCH_SETTINGS:
			if (debug) {
				console.log('Retrieved settings from the server', payload)
			}
			// console.log(payload);
			
            return {
                ...state,
                settings: payload.settings
            };
    }
};

export default configuration;